import React, {useEffect} from 'react';

import {
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import Course from './Course/Course.js';

import Grow from '@material-ui/core/Grow';

import {Card, Button, Container, Row, Col} from "react-bootstrap"

import '../../App.css';

function Teaching() {

  let { path, url } = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <Switch>
          <Route exact path={`${path}`}>

        <div id="publications">
            <h1 className="page-title" >Aulas</h1>

                <h5>Instituto Federal de São Paulo - 2020</h5>

                <Container className="courses_container">
                  <Row>

                    <Grow in={true} timeout={600}>
                      <Col lg={4} sm={6} xs={12}>
                          <Card className="courses_card">
                            <Card.Img variant="top" src="/teaching/A6LP2/a6lp2.png" />
                            <Card.Body>
                              <Card.Title>Linguagem de Programação .NET 2</Card.Title>
                                <Link to={`${path}A6LP2`}>
                                  <Button variant="primary" size="sm">Veteranos 3º ano</Button>
                                </Link>
                            </Card.Body>
                          </Card>
                      </Col>
                    </Grow>

                    <Grow in={true} timeout={900}>
                      <Col lg={4} sm={6} xs={12}>
                          <Card className="courses_card">
                            <Card.Img variant="top" src="/teaching/BDD/database.jpeg" />
                            <Card.Body>
                              <Card.Title>Banco de dados</Card.Title>
                              <Link to={`${path}BDD`}>
                                <Button variant="primary" size="sm">Turma 231</Button>
                              </Link>
                            </Card.Body>
                          </Card>
                      </Col>
                    </Grow>

                    <Grow in={true} timeout={1200}>
                        <Col lg={4} sm={6} xs={12}>
                            <Card className="courses_card">
                              <Card.Img variant="top" src="/teaching/LP3_T1/lp3.jpg" />
                              <Card.Body>
                                <Card.Title>Linguagem de Programação 3</Card.Title>
                                <Link to={`${path}LP3_T1`}>
                                <Button variant="primary" size="sm">Turma 331</Button>
                                </Link>
                                <Link to={`${path}LP3_T2`}>
                                <Button variant="primary" size="sm">Turma 313</Button>
                                </Link>
                              </Card.Body>
                            </Card>
                        </Col>
                    </Grow>
                  </Row>
                </Container>    
                
                <hr />
        </div>

        </Route>

        <Route exact path={`${path}:code`}>
             <Course className="course" />
        </Route>
    </Switch>

   
  );
}

export default Teaching;
