import React, {useEffect, useState } from 'react';
import Grow from '@material-ui/core/Grow';

import { fadeIn  } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import classNames from 'classnames';

import { Image } from 'react-bootstrap';

import ReactGA from 'react-ga';

import RandomText from './RandomText';

import {
  useRouteMatch
} from "react-router-dom";

import '../../App.css';

import style from './tools.module.css';


const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  }
}

function About() {

  let { url } = useRouteMatch();

  const [selectedTool, setSelectedTool] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleReturnTools = () => {
    setSelectedTool(0);
  }

  return (
    <div>

    <h1 className="page-title">Ferramentas</h1>
          <div className="content_body">

            {selectedTool == 0 &&
              <>

              <div className="row">
                  <div className={classNames("col-md-3 col-sm-3", style.tool_card)} onClick={() => setSelectedTool(1)}>            
                    <Grow in={true} timeout={250}>
                      <div className={classNames("card",style.tool_card_content)} style={styles.fadeIn}>
                        <img src="/tools/text_thumb.jpg" className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">Gerador de texto</h5>
                          <p className={style.tool_card_description}>Simples gerador de texto para facilitar testes de interface.</p>
                        </div>
                      </div>
                      </Grow>
                  </div>
              </div>  


              </>

            }

            {selectedTool ==  1 &&
              <RandomText handleReturnTools={handleReturnTools} />
            } 

          </div>


    </div>
  );
}

export default About;