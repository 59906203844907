import React from 'react';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

  

  
import { ResponsiveEmbed } from 'react-bootstrap';

import '../../../App.css';

function CmccTalks() {
  return (
    <div>

      <h4 className="page-title">Comportamente Emergente em Sistemas-de-sistemas - Apresentação CCMM Talks 2018</h4>
      <h7><i>Publicado no dia 17 de fevereiro de 2020</i></h7> 
      
      <br /> <br />


      <p>Em outubro de 2020 tive a oportunidade de apresentar meu projeto de mestrado no I CMCC Talks. </p>
        
        {/* <p>Estamos presenciando o grande número de inovações em hardware
         como miniaturização, economia de energia de sensores e baterias, baixo custo
de processamento e alto poder de armazenamento, e também em software, tecnologias da comunicação etc. Todas essas evoluções, de certo modo
contribuem para que a conexão de produtos seja tecnologiacamente e conomicamente mais viável,
tornando possível a integração entre computadores e produtos.
      </p> */}

      <div style={{ width: 'auto', height: 'auto' }}>
      <ResponsiveEmbed aspectRatio="16by9">
        <iframe
          src="https://www.youtube.com/embed/_0RmFUfMfMM" 
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </ResponsiveEmbed>
    </div>

    <br /><br />

    </div>
  );
}

export default CmccTalks;
