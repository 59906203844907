import React from 'react';

import {
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

  import { Accordion, Card } from 'react-bootstrap'; 

import '../../../../App.css';

function PFDA1() {

  let { path, url } = useRouteMatch();

  return (
    <div className="course">
      <h3 className="page-title" id="topo" >PFDA1 - Prática e Ferramentas de Desenvolvimento de Software</h3>

      <h6>Tecnologia em Análise e Desenvolvimento de Sistemas</h6>
          <h6>1º Semestre - 2020 </h6>
          
          <h6>Professor: Thiago Inocêncio</h6> <br/>

          <h5 class="page-sub-title">Avisos importantes (fique atento sempre!)</h5>

          <p>

            [30/03] Últimos avisos; <br />   <br /> 

            - A reitoria do IFSP lançou uma <a href="https://www.ifsp.edu.br/images/reitoria/Portarias/2020/portaria1200.pdf" target="_blank">
                portaria</a> suspendendo por tempo indeterminado as aulas presenciais e à distância; <br /> 
            - Infelizmente não consiguiremos realizar/adiantar as aulas remotamente por conta de diversos fatores que foram levantados junto aos alunos; <br />
            - Neste momento estou preparando conteúdo audiovisual complementar com base no conteúdo da disciplina;<br /><br />

            - Quaisquer dúvidas ou sugestões envie um e-mail para: <a href="mailto:inocencio.thiago@ifsp.edu.br">inocencio.thiago@ifsp.edu.br</a>.



            <br/><br/>    
            [20/02] - <a href="/teaching/PFDA1/files/lista1.pdf" target="_blank">Lista de Atividades 1 liberada</a> (atividade extra do dia 25/02). <br/>
            [11/02] - <a href="https://spo.ifsp.edu.br/destaques/2144-atividades-suspensas-no-campus-no-periodo-matutino-amanh%C3%A3,-11-02-2020,-para-limpeza-e-organiza%C3%A7%C3%A3o-dos-espa%C3%A7os" target="_blank">
            A aula de hoje está cancelada devido aos alagamentos na cidade.</a> <br/>
            [08/02] - Página da disciplina no ar.
          </p>


   
          <br/>
          <h5 class="page-sub-title">Conteúdos desta página</h5>
          
          {/*<p>
            <img src="/teaching/PFDA1/images/tirinha785.png" class="img-fluid" />
          </p>*/}


          <Accordion >
            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="0">
                Dias, horários e local das aulas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>Terças-feiras, das 21h20 às 22h50, sala (a definir)</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>
            
            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="1">
                Dias, horários e local de atendimento
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                <p>Terças-feiras, das 16h às 18h e das 20h20 às 21h20, sala dos professores.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="2">
                Ementa, objetivos e conteúdo programático da disciplina
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                <Card.Body>
                <h6>Ementa</h6>
          
                <p>
                    O componente curricular apresenta as práticas e as ferramentas básicas de
                    desenvolvimento de software como controle de versão, ambientes integrados de
                    desenvolvimento e guias de estilo de código, de forma a capacitar o aluno no
                    desenvolvimento de projetos individuais e em equipe.           
                </p>

                <h6>Objetivos</h6>

                <p>
                    Descrever e utilizar as principais ferramentas empregadas no desenvolvimento de software
                    como controle de versão, ferramentas de comunicação e redes sociais de desenvolvimento.
                    Configurar e utilizar ambientes integrados de desenvolvimento e suas ferramentas tais como
                    editor e depurador. Aplicar as boas práticas de desenvolvimento de software, no que se
                    refere à padronização de código, baseada em guias de estilos. Desenvolver a capacidade
                    de encontrar soluções para problemas, consultando documentações oficiais, redes sociais
                    para desenvolvedores e outras fontes de informações técnicas. Conhecer e compreender as
                    principais definições, fundamentos e filosofia do movimento de software livre. Conhecer os
                    aspectos jurídicos do software livre e as licenças de software.
                </p>

                <h6>Conteúdo programático</h6>

                <p><b>Terminal e Linha de Comando</b>: Shell, Comandos e Navegação, Manipulação de Arquivos e Diretórios, Configuração e Ambiente; 
                    <b>Controle de Versão</b>: Conceitos básicos, Modelos centralizado e distribuído, Introdução ao Git, Repositórios remotos, Trabalho em equipe;
                    <b>Ambiente de Desenvolvimento</b>: Editores de Textos e IDES, Configuração e Plugins, Depuração;
                    <b>“Aprender a Pesquisar”</b>: Documentações oficiais, Redes sociais para desenvolvedores, Fontes de notícias e informações técnicas, Sites de perguntas e respostas;
                    <b>Software Livre</b>: Filosofia, Cultura e História, Aspectos Jurídicos,  Contribuição em Projetos;
                    <b>Boas Práticas de Desenvolvimento</b>: Padronização, Guias de estilo de código;
                </p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="3">
                Cronograma
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                <Card.Body>
                <p>É obrigatória a frequência às aulas e demais atividades escolares. A frequência mínima para efeitos de aprovação
                 é de 75% (setenta e cinco por cento) sobre o total de aulas e demais atividades escolares.</p>

                 <p><b>Exemplo:</b> Para uma disciplina com 32 h/a (trinta e duas horas/aula) o aluno só terá direito a 8 (oito) faltas.</p>

                <p><b>Ver também</b> (<a href="#abono-faltas">ABONO DE FALTAS</a>).</p>
                
                <table class="table_crono">
                    <thead>
                        <tr>
                            <th class="table_th_weak">Semana</th> 
                            <th class="table_th_date">Data</th> 
                            <th class="table_th_content">Conteúdo</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="table_th_weak">1</td>
                            <td class="table_th_date">11/02/2020</td>
                            <td class="table_td_content"><span style={{textDecoration: "line-through"}}>Introdução à disciplina</span> (Aula cancelada).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">2</td>
                            <td class="table_th_date">18/02/2020</td>
                            <td class="table_td_content">Introdução à disiplina.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">3</td>
                            <td class="table_th_date">25/02/2020</td>
                            <td class="table_td_content">Feriado Carnaval - <b><a href="/teaching/PFDA1/files/lista1.pdf" target="_blank">(Atividade extra em 29/02/2020).</a></b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">4</td>
                            <td class="table_th_date">03/03/2020</td>
                            <td class="table_td_content" style={{borderLeft: "2px solid rgba(199, 199, 199, 0.775)"}}><b>Terminal e Linha de Comando I:</b> <br/>
                            - <i>Shell Script</i>: <a href="/teaching/PFDA1/files/TLCL/chapters/1.pdf" target="_blank">Capítulo 1</a> de [<a href="/teaching/PFDA1/files/TLCL/book.pdf" target="_blank">TLCL</a>]; <br/>
                            - <i>Comandos de navegação</i>: <a href="/teaching/PFDA1/files/TLCL/chapters/2.pdf" target="_blank">Capítulo 2</a> de [<a href="/teaching/PFDA1/files/TLCL/book.pdf" target="_blank">TLCL</a>]; <br/>
                            - <i>Comandos para manipulação de Arquivos e Diretórios</i>: <a href="/teaching/PFDA1/files/TLCL/chapters/4.pdf" target="_blank">Capítulo 4</a> de [<a href="/teaching/PFDA1/files/TLCL/book.pdf" target="_blank">TLCL</a>]; <br/>
                            
                            <br/>
                            <b>Links</b> <br/>
                            - <a href="https://bit.ly/2v9hV0L" target="_blank">Linux Command line Cheat Sheet</a> <br/>
                            - <a href="https://linuxhint.com/30_bash_script_examples/#t1" target="_blank">30 Bash Script Examples</a>
                    
                            <br/><br/>
                            <b>Vídeos</b><br/>
                            - <a href="https://www.youtube.com/watch?v=kKglzOgMhzw" target="_blank">Instalando de forma FÁCIL o Ubuntu dentro do Windows 10</a> <br/>
                            - <a href="https://www.youtube.com/watch?v=zsqJhle7CXE" target="_blank">Como Instalar Linux Ubuntu 18.04 LTS passo a passo no VirtualBox</a>
                            <br/>
                    
                            </td>
                            </tr>
                        
                        <tr>
                            <td class="table_th_weak">5</td>
                            <td class="table_th_date">10/03/2020</td>
                            <td class="table_td_content"><b>Terminal e Linha de Comando I:</b> <br/>
                            <i>Configuração e Ambiente</i>: <a href="/teaching/PFDA1/files/TLCL/chapters/11.pdf" target="_blank">Capítulo 11</a> de [<a href="/teaching/PFDA1/files/TLCL/book.pdf" target="_blank">TLCL</a>]; <br/></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">6</td>
                            <td class="table_th_date">17/03/2020</td>
                            <td class="table_td_content">Controle de Versão I: Conceitos básicos.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">7</td>
                            <td class="table_th_date">24/03/2020</td>
                            <td class="table_td_content">Controle de Versão II: Introdução ao Git.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">8</td>
                            <td class="table_th_date">31/03/2020</td>
                            <td class="table_td_content">Controle de Versão III: Repositórios remotos (github, gitlab, bitbucket etc).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">9</td>
                            <td class="table_th_date">07/04/2020</td>
                            <td class="table_td_content">Controle de Versão IV: Trabalho em equipe em repositórios de controle de versão remotos.</td>
                        </tr>
                        
                        <tr>
                            <td class="table_th_weak">10</td>
                            <td class="table_th_date">14/04/2020</td>
                            <td class="table_td_content"><b>Avaliação I (AVI).</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">11</td>
                            <td class="table_th_date">21/04/2020</td>
                            <td class="table_td_content">Feriado Tiradentes <b>(Atividade extra).</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">12</td>
                            <td class="table_th_date">28/04/2020</td>
                            <td class="table_td_content">Ambiente de Desenvolvimento I: Editores de Textos e IDES.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">13</td>
                            <td class="table_th_date">05/05/2020</td>
                            <td class="table_td_content">Ambiente de Desenvolvimento II: Configuração, Plugins e Depuração.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">14</td>
                            <td class="table_th_date">12/05/2020</td>
                            <td class="table_td_content">Conselho pedagógico (Atividade Extra).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">15</td>
                            <td class="table_th_date">19/05/2020</td>
                            <td class="table_td_content">Comunidade de desenvolvimento I: Documentações oficiais e Redes sociais para desenvolvedores</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">16</td>
                            <td class="table_th_date">26/05/2020</td>
                            <td class="table_td_content">Comunidade de desenvolvimento II: Fontes de notícias e informações técnicas e Sites de perguntas e respostas e forums.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">17</td>
                            <td class="table_th_date">02/06/2020</td>
                            <td class="table_td_content">Comunidade de desenvolvimento III: </td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">18</td>
                            <td class="table_th_date">09/06/2020</td>
                            <td class="table_td_content">Software Livre: Filosofia, Cultura, História, Aspectos Jurídicos e Contribuição em Projetos.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">19</td>
                            <td class="table_th_date">16/06/2020</td>
                            <td class="table_td_content">Boas práticas de Desenvolvimento: Padronização e Guias de estilo de código.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">20</td>
                            <td class="table_th_date">23/06/2020</td>
                            <td class="table_td_content"><b>Avaliação II (AVII).</b></td>
                        </tr>
                    </tbody>
                        
                    
                </table>
                </Card.Body>
                </Accordion.Collapse>
            </Card>


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="4">
                Bibliografia
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body>
                <h6>Básica</h6>
          
                <p>CHACON, Scott; STRAUB, Ben. Pro git. 2. ed. New York: Apress, 2014. ISBN
                    9781484200773. Disponível em: https://git-scm.com/book/en/v2. Acesso em: 19 mai. 2019.</p>
                    
                <p><b>[TLCL]</b> SHOTTS JR, William E. The linux command line: a complete introduction. 5. ed. San
                    Francisco: No Starch Press, 2019. Disponível em: <a href="http://linuxcommand.org/tlcl.php" target="_blank">http://linuxcommand.org/tlcl.php</a> Acesso
                    em: 14 jun. 2019.</p>

                <p>WARD, Brian. Como o linux funciona: o que todo superusuário deveria saber. 1. ed. São
                    Paulo: Novatec, 2015. ISBN 9788575225783. </p>
                    
                <h6>Complementar</h6>
                
                <p>BELL, Peter; BEER, Brent. Introdução ao GitHub: um guia que não é técnico. São Paulo:
                    Novatec, 2014. ISBN 9788575224144.</p>
                    
                <p>DIAS, José Carlos Vaz. Propriedade intelectual e os dez anos da lei de inovação:
                    conflitos e perspectivas. Rio de Janeiro: Gamma, 2015.</p>

                <p>EIRAS, Marcelo; MENDONÇA, Nelson. Guia de certificação Linux. 2. ed. Rio de Janeiro:
                    Brasport, 2004. ISBN 9788574521596.</p>

                <p>SILVEIRA, Newton. Propriedade intelectual: propriedade industrial, direito de autor,
                    software, cultivares, nome empresarial. 4. ed. São Paulo: Manole, 2011. ISBN
                    9788520431696. Disponível em:
                    https://ifsp.bv3.digitalpages.com.br/users/publications/9788520431696. Acesso em: 14 jun.
                    2019.</p>

                <p>SOMMERVILLE, Ian. Engenharia de software. 9. ed. São Paulo: Pearson Prentice Hall,
                    2011. ISBN 9788579361081. Disponível em:
                    https://ifsp.bv3.digitalpages.com.br/users/publications/9788579361081. Acesso em: 14 jun.
                    2019.</p>     
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="5">
                Critérios de avaliação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body>
                <h6>Instrumentos de Avaliação:</h6>
            
                <ul>
                    <li>Avaliação I (AVI);</li>
                    <li>Avaliação II (AVII);</li>
                    <li>Projeto (PJ)</li>
                    <li>Exercícios (EX).</li>
                </ul>
            
                <h6>Critérios de Avaliação:</h6>
                <br/>

                <p class="content_centralized">
                MD = (AVI * 2.5) + (AVII * 2.5) + (PJ * 2.5) + (EX * 2.5)
                </p>

                <p>O aluno será considerado <b>aprovado</b> na disciplina se sua média final (MD) for maior ou igual a 6.0 (MD >= 6.0) e tiver <b>frequência mínima</b> de 75% (setenta e cinco por cento)
                    às aulas e demais atividades escolares.</p>
                    
                <p>O aluno será considerado <b>reprovado</b> na disciplina se não tiver <b>frequência mínima</b> de 75% (setenta e cinco por cento) e/ou média final na disciplina após o IFA for menor que 6.</p>  
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="6">
                Mecanismos de recuperação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body>
                <p>
                    Para os alunos que obtiverem média final (MD) maior ou igual a 4,0 (MD >= 4.0) e menor que 6,0, será aplicado o IFA 
                    (Instrumento Final de Avaliação), desde que possuam frequência mínima de 75%.
                </p>

                <p>Nesse caso a média final do aluno será a nota obtida no IFA.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="7">
                Abono de faltas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                <Card.Body>
                <p>O Art. 43, da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP</a>, prevê o abono das faltas nos seguintes casos:</p>
            
                <ul>
                    <li>Declaração de corporação militar, comprovando ausência;</li>
                    <li>Comprovante de participação do estudante em reuniões da Comissão Nacional de Avaliação da educação superior (CONAES) em horário coincidente com as atividades acadêmica (Lei nº 10861/04);</li>
                    <li>Declaração do Diretor-Geral do campus, comprovando que o estudante esteve representando o IFSP;</li>
                    <li>Atestado médico para os casos previstos em lei.</li>
                    <li>Certidão de óbito de parentes de 1º (primeiro) grau ou cônjuge;</li>
                    <li>Solicitação judicial.</li>
                </ul>

                <p>Para maiores informações consultar a <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="8">
                Aproveitamento da disciplina
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                <Card.Body>
                <p>Conforme o Art. 101 da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP</a>: Os estudantes terão direito a aproveitamento de estudos dos 
                componentes curriculares já cursados, com aprovação no IFSP ou em instituição congênere, desde que realizados com êxito e dentro do mesmo nível de ensino.</p>

                <p>Para maiores informações consultar o CAPÍTULO VI da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>   
                </Card.Body>
                </Accordion.Collapse>
            </Card>


            </Accordion>

            <br />
            <br />
            <br />
            <br />
          
    </div>
  );
}

export default PFDA1;
