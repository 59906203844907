import React from 'react';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

import CmccTalks from './CmccTalks.js';

import '../../../App.css';

function Article() {

  let { path, url } = useRouteMatch();

  let { article } = useParams();

  const articleToRender = () => {
      switch(article) {
          case "apresentacao_cmcc_talks": return (<CmccTalks />)
      }
  }

  return (
    <div>
      {articleToRender()}   
    </div>
  );
}

export default Article;
