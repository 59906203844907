import React from 'react';
import Grow from '@material-ui/core/Grow';
import {
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  
  import Article from './Article/Article.js';

  import '../../App.css';


function Blog() {

    let { path, url } = useRouteMatch();


  const listBlogPublication = [
      {
          title: "Comportamente Emergente em Sistemas-de-sistemas - Apresentação CCMM Talks 2018",
          date: "17/03/2020 às 02:45",
          brief: "Em outubro de 2020 tive a oportunidade de apresentar meu projeto de mestrado no I CMCC Talks...",
          route: "apresentacao_cmcc_talks",
          component: "test"
      }
  ]  


  return (
    <Switch>
          <Route exact path={`${path}`}>
            <div  id="blog">
            <h1 className="page-title">Blog</h1>

            {
                listBlogPublication.map(publication => {
                    
                        return ( 
                            <Grow in={true} timeout={150}> 
                            <p key={publication.route}><Link to={`${url}/${publication.route}`}>{publication.title}</Link> (postado em {publication.date}).</p>
                            </Grow>
                        )
                    
                })
            }
            </div>

            </Route>

            <Route exact path={`${path}/:article`}>
                <Article />
            </Route>
          
     </Switch>
  );
}

export default Blog;
