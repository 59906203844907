import React from 'react';

import {
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

  import { Accordion, Card } from 'react-bootstrap'; 

import '../../../../App.css';

function BDD() {

  let { path, url } = useRouteMatch();

  return (
    <div className="course">
      <h3 className="page-title" id="topo" >BDD - Banco de dados</h3>

      <h6>Instituto Federal de Educação, Ciência e Tecnologia de São Paulo, Campus São Paulo.</h6> 
         
      <h6>2020 - Tecnico em Informática Integrado ao Ensino Médio</h6> 
    
      <h6>Professor: Thiago Inocêncio</h6> <br />  

      <h5 class="page-sub-title">Avisos importantes (fique atento sempre!)</h5>

      <p>

            [30/03] Últimos avisos; <br />   <br /> 

            - A reitoria do IFSP lançou uma <a href="https://www.ifsp.edu.br/images/reitoria/Portarias/2020/portaria1200.pdf" target="_blank">
                portaria</a> suspendendo por tempo indeterminado as aulas presenciais e à distância; <br /> 
            - Infelizmente não consiguiremos realizar/adiantar as aulas remotamente por conta de diversos fatores que foram levantados junto aos alunos; <br />
            - Neste momento estou preparando conteúdo audiovisual complementar com base no conteúdo da disciplina;<br /><br />

            - Quaisquer dúvidas ou sugestões envie um e-mail para: <a href="mailto:inocencio.thiago@ifsp.edu.br">inocencio.thiago@ifsp.edu.br</a>.



            <br/><br/>


            [09/03] - <a href="/teaching/BDD/files/lista1.pdf" target="_blank">
             Lista de exercícios 1 liberada.</a> <br />
            [10/02] - <a href="https://spo.ifsp.edu.br/destaques/2143-atividades-suspensas-em-10-02-devido-a-alagamentos-na-cidade" target="_blank">
            A aula de hoje está cancelada devido aos alagamentos na cidade.</a> <br />
             [08/02] - Página da disciplina no ar.</p>

             <br />

        <Accordion>
            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="0">
                Dias, horários e local das aulas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <p>(TURMA 1) Segundas-feiras, das 13h15 às 15h30, sala (a definir)</p>
                    <p>(TURMA 2) Segundas-feiras, das 15h45 às 18h00, sala (a definir)</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>  


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="1">
                Dias, horários e local de atendimento
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <p>Terças-feiras, das 16h às 18h e das 20h20 às 21h20, sala dos professores.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card> 


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="2">
                Ementa da disciplina
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                <Card.Body>
                <p>
                    A disciplina apresenta o conceito de banco de dados e as técnicas para o projeto e implementação de bancos de
                    dados em aplicações de software. Enfatiza o modelo relacional e a linguagem de consulta SQL. Apresenta elementos
                    de um sistema gerenciador de banco de dados e como configurá-lo e utilizá-lo.           
                </p>

                <h6>Objetivos</h6>

                <p>
                    Identificar, Diferenciar Banco de Dados, Sistema de Banco de Dados e Sistema Gerenciador de Banco de Dados;
                    Conhecer e distinguir as etapas do Projeto de um banco de dados; Conhecer o Modelo EntidadeRelacionamento e aplicar na construção de um modelo conceitual para o banco de dados; Conhecer o
                    Modelo Relacional e empregá-lo na construção de um esquema lógico para um banco de dados, a partir do
                    esquema conceitual; Analisar e discutir a normalização de um esquema lógico de banco de dados;
                    Conhecer os fundamentos da SQL (Structured Query Language); Desenvolver o modelo físico do banco de
                    dados usando um Sistema Gerenciador de Banco de Dados. Desenvolver um sistema de banco de dados utilizando
                    um sistema de gerenciamento de banco de dados; Conhecer e aplicar os recursos existentes para a implementação
                    de regras de negócios, por meio de restrições de integridade, procedimentos, funções e gatilhos; Conhecer as tarefas
                    e procedimentos tomados por um administrador de banco de dados, como aplicar os aspectos operacionais do SGBD
                    para prover segurança, recuperação de falhas e controle de concorrência; Conhecer e aplicar os métodos de
                    execução e otimização de consultas em SGBD; Diferenciar as principais arquiteturas de bancos de dados.
                </p>

                </Card.Body>
                </Accordion.Collapse>
            </Card> 


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="3">
                Cronograma
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                <Card.Body>
                <p>É obrigatória a frequência às aulas e demais atividades escolares. A frequência mínima para efeitos de aprovação
                    é de 75% (setenta e cinco por cento) sobre o total de aulas e demais atividades escolares.</p>

                    <p>Nesta disciplina é necessária uma frequência de aproximadamente 30 semanas.</p>

                <p class="content_centralized">frequência ` \approx ` 30 semanas.</p>


                <p><b>Ver também</b> (<a href="#abono-faltas">ABONO DE FALTAS</a>).</p>


                <table class="table_crono">
                    <thead>
                        <tr>
                            <th class="table_th_weak">Semana</th> 
                            <th class="table_th_date">Data</th> 
                            <th class="table_th_content">Conteúdo</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="table_th_weak">1</td>
                            <td class="table_th_date">10/02/2020</td>
                            <td class="table_td_content"><span style={{textDecoration: "line-through"}}>Introdução à disciplina</span> (Aula cancelada)</td>
                        </tr>
                        
                        <tr>
                        <td class="table_th_weak">2</td>
                        <td class="table_th_date">17/02/2020</td>
                        <td class="table_td_content">Introdução à disciplina.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">3</td>
                        <td class="table_th_date">24/02/2020</td>
                        <td class="table_td_content">Recesso Acadêmico-administrativo <b>(Feriado Carnaval - atividade extra em 29/02/2020).</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">4</td>
                        <td class="table_th_date">02/03/2020</td>
                        <td class="table_td_content"><a href="/teaching/BDD/files/lectures/Aula 1 - Introdução a Banco de Dados.pdf" target="_blank">Fundamentos de Banco de dados.</a></td>
                        </tr>
                        
                        <tr>
                        <td class="table_th_weak">5</td>
                        <td class="table_th_date">09/03/2020</td>
                        <td class="table_td_content"><a href="/teaching/BDD/files/lectures/Aula 2 – Visão Geral da Estrutura de um Banco de Dados.pdf" target="_blank">Visão Geral da estrutura de um banco de dados.</a></td>
                        </tr>
                        
                        <tr>
                        <td class="table_th_weak">6</td>
                        <td class="table_th_date">16/03/2020</td>
                        <td class="table_td_content">Projeto Conceitual - Modelo entidade-relacionamento</td>
                        </tr>
                        
                        <tr>
                        <td class="table_th_weak">7</td>
                        <td class="table_th_date">23/03/2020</td>
                        <td class="table_td_content">Projeto Conceitual II - Conceitos avançados de relacionamento</td>
                        </tr>
                        
                        <tr>
                        <td class="table_th_weak">8</td>
                        <td class="table_th_date">30/03/2020</td>
                        <td class="table_td_content">Exercícios e Revisão</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">9</td>
                        <td class="table_th_date">06/04/2020</td>
                        <td class="table_td_content"><b>Avaliação do 1º Bimestre</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">10</td>
                        <td class="table_th_date">13/04/2020</td>
                        <td class="table_td_content">Correção da avaliação e aplicação da avaliação de recuperação (última aula do 1º bimestre)</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">11</td>
                        <td class="table_th_date">27/04/2020</td>
                        <td class="table_td_content">Projeto lógico I - Modelo lógico relacional</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">12</td>
                        <td class="table_th_date">04/05/2020</td>
                        <td class="table_td_content">Projeto Lógico II - Regras avançadas</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">13</td>
                        <td class="table_th_date">11/05/2020</td>
                        <td class="table_td_content">Conselho pedagógico <b>(Atividade extra em 16/05/2020).</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">14</td>
                        <td class="table_th_date">18/05/2020</td>
                        <td class="table_td_content">Projeto Lógico III - Exercícios de fixação.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">15</td>
                        <td class="table_th_date">25/05/2020</td>
                        <td class="table_td_content">Projeto Lógico IV - Exercícios de fixação.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">16</td>
                        <td class="table_th_date">01/06/2020</td>
                        <td class="table_td_content">Projeto Físico de Banco de dados I - Fundamentos.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">17</td>
                        <td class="table_th_date">08/06/2020</td>
                        <td class="table_td_content">Projeto Físico de Banco de dados II - Utilização de SGBD para criação do banco de dados.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">18</td>
                        <td class="table_th_date">15/06/2020</td>
                        <td class="table_td_content">Exercícios e revisão.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">19</td>
                        <td class="table_th_date">22/06/2020</td>
                        <td class="table_td_content"><b>Avaliação do 2º Bimestre.</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">20</td>
                        <td class="table_th_date">29/06/2020</td>
                        <td class="table_td_content">Correção da avaliação e aplicação da avaliação de recuperação (última aula do 2º bimestre).</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">21</td>
                        <td class="table_th_date">03/08/2020</td>
                        <td class="table_td_content">Linguagem SQL I - Introdução a linguagem de consulta estruturada.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">22</td>
                        <td class="table_th_date">10/08/2020</td>
                        <td class="table_td_content">Linguagem SQL II - Tipos de dados. Criação de bancos de dados e tabelas.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">23</td>
                        <td class="table_th_date">17/08/2020</td>
                        <td class="table_td_content">Linguagem SQL III - Tipos de dados; Criação de bancos de dados e tabelas.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">24</td>
                        <td class="table_th_date">24/08/2020</td>
                        <td class="table_td_content">Linguagem SQL IV - Inserção de dados.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">25</td>
                        <td class="table_th_date">31/08/2020</td>
                        <td class="table_td_content">Linguagem SQL V - Atualização e exclusão de dados.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">26</td>
                        <td class="table_th_date">07/09/2020</td>
                        <td class="table_td_content">Feriado (Dia da independência) - Atividade Extra 
                            (Participação da SEDCITEC e produção de relatórios de acompanhamento de palestras e atividades.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">27</td>
                        <td class="table_th_date">14/09/2020</td>
                        <td class="table_td_content">Linguagem SQL VI - Exercícios de revisão e fixação.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">28</td>
                        <td class="table_th_date">21/09/2020</td>
                        <td class="table_td_content"><b>Avaliação do 3º Bimestre.</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">29</td>
                        <td class="table_th_date">28/09/2020</td>
                        <td class="table_td_content">Correção da avaliação e aplicação da avaliação de recuperação (última aula do 3º bimestre).</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">30</td>
                        <td class="table_th_date">05/10/2020</td>
                        <td class="table_td_content">Linguagem SQL VII - Introdução a consultas. Cláusulas de consulta.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">31</td>
                        <td class="table_th_date">12/10/2020</td>
                        <td class="table_td_content">Feriado Nossa Senhora <b>(Atividade extra - exercícios de fixação 08/02/2020).</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">32</td>
                        <td class="table_th_date">19/10/2020</td>
                        <td class="table_td_content">Linguagem SQL VIII - Filtragem (condições), junções (consulta em múltiplas tabelas) e agrupamentos.</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">33</td>
                        <td class="table_th_date">26/10/2020</td>
                        <td class="table_td_content">Linguagem SQL IX - Funções nativas e definidas pelo usuário, visões (views), procedimentos (stored procedures).</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">34</td>
                        <td class="table_th_date">02/11/2020</td>
                        <td class="table_td_content">Feriado Finados <b>(Atividade extra - exercícios de fixação 08/02/2020).</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">35</td>
                        <td class="table_th_date">09/11/2020</td>
                        <td class="table_td_content">Linguagem SQL IX - Índices (otimização de consultas), restrições de integridade e gatilhos (triggers).</td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">36</td>
                        <td class="table_th_date">16/11/2020</td>
                        <td class="table_td_content">Linguagem SQL XI - Transações, controle de concorrência e recuperação de falhas.</td>
                        </tr>
                        
                        <tr>
                        <td class="table_th_weak">37</td>
                        <td class="table_th_date">23/11/2020</td>
                        <td class="table_td_content"><b>Avaliação do 4º Bimestre.</b></td>
                        </tr>

                        <tr>
                        <td class="table_th_weak">38</td>
                        <td class="table_th_date">30/11/2020</td>
                        <td class="table_td_content">Correção da avaliação e aplicação da avaliação de recuperação (última aula do 4º bimestre).</td>
                        </tr>

                    
                    </tbody>
                        
                </table>

                </Card.Body>
                </Accordion.Collapse>
            </Card> 

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="4">
                Bibliografia
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body>
                <h6>Básica</h6>
          
                <p>ELMASRI, R.; NAVATHE S. B.; Sistemas de Banco de Dados. 4ª edição. Editora Pearson, 2005.</p>
                    
                <p>HEUSER, C. A.; Projeto de Banco de Dados. 6ª edição. Editora Artmed, 2009.</p>

                <p>RAMAKRISHNAN, R.; GEHRKE, J.; Sistemas de Gerenciamento de Banco de Dados. 3ª edição. Editora Mc Graw-Hill,
                    2008. </p>
                    
                <h6>Complementar</h6>
                
                <p>DATE, C. J.; Introdução a Sistemas de Bancos de Dados. 8ª edição. Editora Campus, 2004.
                </p>
                    
                <p>DEWSON, R.; Microsoft SQL Server 2008 para Desenvolvedores. 1ª edição. Editora Alta Books</p>

                <p>GONZAGA, J. L.; Dominando o PostgreSQL. 1ª edição. Editora Ciência Moderna, 2007.
                </p>

                <p>SETZER, V. W.; Silva, F. S. C.; Bancos de dados. 2ª edição. Editora Edgard Blucher, 2005.</p>

                    <p>SILBERCHATZ, A. ; KORTH, H. F.; SUDARSHA, S.; Sistema de Banco de Dados. 3ª edição. Editora
                        Makron Books, 1999.</p>   

                </Card.Body>
                </Accordion.Collapse>
            </Card> 


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="5">
                Instrumentos e Critérios de Avaliação da Aprendizagem 
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body>
                <h6>Instrumentos de Avaliação bimestral:</h6>
        
                <ul>
                    <li>Avaliação (`AV`);</li>
                    <li>Listas de exercícios (`LE`);</li>
                </ul>
                
            
                <h6>Critérios de Avaliação:</h6>

                <p class="content_centralized">
                    `MB = (AV * 0.7) + (LE * 0.3)`
                </p>
                
                <p>
                    *`MB` (Média bimestral)
                </p>
                    
                <p>O aluno será considerado <b>aprovado</b> na disciplina se sua média final (`MD`) for maior ou igual a `6.0` (`MD >= 6.0`) e tiver <b>frequência mínima</b> de 75% (setenta e cinco por cento)
                    às aulas e demais atividades escolares.</p>
                    
                <p>O aluno será considerado <b>reprovado</b> na disciplina se não tiver <b>frequência mínima</b> de 75% (setenta e cinco por cento) e/ou média final na disciplina após a prova de recuperação for menor que 6.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card> 

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="6">
                Mecanismos de recuperação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body>
                <p>
                    Para os alunos que obtiverem nota na avaliação bimestral menor que 6,0, será aplicada uma avaliação de recuperação. A nota
                    final de avaliação de recuperação substituirá a nota de avaliação inicial, caso seja superior. Para os alunos que persistirem com média bimestral
                    menor que 6,0, após a avaliação ou trabalho de recuperação, será oferecida uma ou mais aulas de Recuperação Paralela em datas e horários
                    a serem definidos.
                </p>

                </Card.Body>
                </Accordion.Collapse>
            </Card>  

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="7">
                Abono de faltas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                <Card.Body>
                <ul>
                    <li>Declaração de corporação militar, comprovando ausência;</li>
                    <li>Comprovante de participação do estudante em reuniões da Comissão Nacional de Avaliação da educação superior (CONAES) em horário coincidente com as atividades acadêmica (Lei nº 10861/04);</li>
                    <li>Declaração do Diretor-Geral do campus, comprovando que o estudante esteve representando o IFSP;</li>
                    <li>Atestado médico para os casos previstos em lei.</li>
                    <li>Certidão de óbito de parentes de 1º (primeiro) grau ou cônjuge;</li>
                    <li>Solicitação judicial.</li>
                </ul>

                <p>Para maiores informações consultar a <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>

                </Card.Body>
                </Accordion.Collapse>
            </Card>



        </Accordion>      

        <br/><br/>
      <br/>
    </div>
  );
}

export default BDD;
