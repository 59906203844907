import React from 'react';

import {
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

  import { Accordion, Card } from 'react-bootstrap'; 

import '../../../../App.css';

function A6LP2() {

  let { path, url } = useRouteMatch();

  return (
    <div className="course">
      <h3 className="page-title" id="topo" >A6LP2 - Linguagem de Programação .NET 2</h3>

      <h6>Tecnologia em Análise e Desenvolvimento de Sistemas</h6>
          <h6>1º Semestre - 2020 </h6>
          
          <h6>Professor: Thiago Inocêncio</h6> <br/>
          
          <h5 class="page-sub-title">Avisos importantes (fique atento sempre!)</h5>
          <p>

             [30/03] Últimos avisos; <br />   <br /> 

            - A reitoria do IFSP lançou uma <a href="https://www.ifsp.edu.br/images/reitoria/Portarias/2020/portaria1200.pdf" target="_blank">
                portaria</a> suspendendo por tempo indeterminado as aulas presenciais e à distância; <br /> 
            - Infelizmente não consiguiremos realizar/adiantar as aulas remotamente por conta de diversos fatores que foram levantados junto aos alunos; <br />
            - Neste momento estou preparando conteúdo audiovisual complementar com base no conteúdo da disciplina;<br /><br />

            - Quaisquer dúvidas ou sugestões envie um e-mail para: <a href="mailto:inocencio.thiago@ifsp.edu.br">inocencio.thiago@ifsp.edu.br</a>.



            <br/><br/>

            [14/03] - <a href="https://www.ifsp.edu.br/component/content/article/17-ultimas-noticias/1308-coronavirus?fbclid=IwAR3K8MIZpcaKayOESIMS_yL_R7QfWi_NUsuxNbX83IUXy8Mi0C_WdLfk5UQ" target="_blank">
            As aulas das semanas 6 e 7 foram suspensas com base em nota emitida pela reitoria em 14/03/2020.</a> <br/>
            [14/03] - <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTwB2o7dwmynne2g9TffwHyUns8vTGpKPxWZmLZdOV7HnsElgN5ZFrjzYNe-z1dRIUG4L3LW4YHKHOY/pubhtml#" target="_blank">
            Notas preliminares de participação</a> (Quaisquer dúvidas me contactem por e-mail).<br/>
            [08/02] - Página da disciplina no ar.</p>
          
            <br/>

            

          <h5 class="page-sub-title">Conteúdos desta página</h5>

            <Accordion >
            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="0">
                Dias, horários e local das aulas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                     <p>Sextas-feiras, das 18h50 às 22h50, no laboratório de informática 3.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="1">
                Dias, horários e local de atendimento 
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                <p>Terças-feiras, das 16h às 18h, no Laboratório	de Informática 6. <br/>
                     Terças-feiras, das 20h20 às 21h20, na sala dos professores.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="2">
                Ementa, objetivos e conteúdo programático da disciplina 
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                <Card.Body>
                <h6>Ementa</h6>
          
                <p>
                    Programação com Windows Forms – desenho de interfaces com o usuário. 
                    Trabalhando com Banco de Dados através de um SGBD.
                    Compilação e Distribuição de Aplicações C#.
                    Introdução à Programação com ASP.Net e C#.
                    Introdução à Programação para Pocket PC   
                </p>

                <h6>Objetivos</h6>

                <p>
                    Apresentar ferramenta de desenvolvimento para o ambiente .Net e capacitar os participantes a desenvolver aplicações básicas com
                    manipulação de bancos de dados através de SGBD (Sistemas Gerenciadores de Banco de Dados).
                    O curso dá especial ênfase aos recursos e controles básicos da linguagem C# e ao design de interfaces do Usuário.
                </p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="3">
                Cronograma
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                <Card.Body>
                <p>É obrigatória a frequência às aulas e demais atividades escolares. A frequência mínima para efeitos de aprovação
                 é de 75% (setenta e cinco por cento) sobre o total de aulas e demais atividades escolares.</p>

                <p><b>Ver também</b> (<a href="#abono-faltas">ABONO DE FALTAS</a>).</p>
                
                <table class="table_crono">
                    <thead>
                        <tr>
                            <th class="table_th_weak">Semana</th> 
                            <th class="table_th_date">Data</th> 
                            <th class="table_th_content">Conteúdo</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="table_th_weak">1</td>
                            <td class="table_th_date">14/02/2020</td>
                            <td class="table_td_content">Apresentação da disciplina  <br/>
                                <a href="/teaching/A6LP2/files/lectures/1-revisao_de_Linguagem_Csharp.pdf" target="_blank">Revisão da Linguagem de Programação C# e Orientaçao a objetos.</a></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">2</td>
                            <td class="table_th_date">21/02/2020</td>
                            <td class="table_td_content"><a href="/teaching/A6LP2/files/lectures/2-Breve_historico_net_asp_net.pdf" target="_blank">Breve Histórico da Tecnologia ASP.NET.</a></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">3</td>
                            <td class="table_th_date">28/02/2020</td>
                            <td class="table_td_content"><a href="/teaching/A6LP2/files/lectures/3-Introducaoo_ao_ASPNET_MVC.pdf" target="_blank">Introdução ao ASP.NET MVC.</a></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">4</td>
                            <td class="table_th_date">06/03/2020</td>
                            <td class="table_td_content"><a href="/teaching/A6LP2/files/lectures/4-ASP_NET_MVC–Models I.pdf" target="_blank">ASP.NET MVC Camada de modelo I</a></td>
                        </tr>
                        
                        <tr>
                            <td class="table_th_weak">5</td>
                            <td class="table_th_date">13/03/2020</td>
                            <td class="table_td_content"><a href="/teaching/A6LP2/files/lectures/5-ASP_NET_MVC–Models_II.pdf" target="_blank">ASP.NET MVC Camada de modelo II</a></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">6</td>
                            <td class="table_th_date">20/03/2020</td>
                            <td class="table_td_content">ASP.NET MVC Camada de modelo III</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">7</td>
                            <td class="table_th_date">27/03/2020</td>
                            <td class="table_td_content">ASP.NET MVC Controladores I</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">8</td>
                            <td class="table_th_date">03/04/2020</td>
                            <td class="table_td_content">ASP.NET MVC Controladores II</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">9</td>
                            <td class="table_th_date">10/04/2020</td>
                            <td class="table_td_content">Feriado (Sexta-feira da paixão - Atividade extra / Exercícios)</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">10</td>
                            <td class="table_th_date">17/04/2020</td>
                            <td class="table_td_content">Avaliação</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">11</td>
                            <td class="table_th_date">24/04/2020</td>
                            <td class="table_td_content">ASP.NET MVC Camada de visualização (views)</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">12</td>
                            <td class="table_th_date">01/05/2020</td>
                            <td class="table_td_content">Feriado (Dia do trabalho - Atividade extra ASP.NET MVC / Repositórios)</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">13</td>
                            <td class="table_th_date">08/05/2020</td>
                            <td class="table_td_content">ASP.NET MVC - Filtros de ação</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">14</td>
                            <td class="table_th_date">15/05/2020</td>
                            <td class="table_td_content">ASP.NET MVC - Validação de Dados</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">15</td>
                            <td class="table_th_date">22/05/2020</td>
                            <td class="table_td_content">ASP.NET MVC - Web Services com ASP.NET Web API I</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">16</td>
                            <td class="table_th_date">29/05/2020</td>
                            <td class="table_td_content">ASP.NET MVC - Web Services com ASP.NET Web API II</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">17</td>
                            <td class="table_th_date">05/06/2020</td>
                            <td class="table_td_content">Especificação dos projetos e formação das equipes (Inicício do desenvolvimento do Projeto)</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">18 </td>
                            <td class="table_th_date">12/06/2020</td>
                            <td class="table_td_content">Recesso Acadêmico administrativo (Atividade extra - continuação do desenvolvimento do projeto</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">19</td>
                            <td class="table_th_date">19/06/2020</td>
                            <td class="table_td_content">Finalização dos projetos - Orientações para finalização dos projetos</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">20</td>
                            <td class="table_th_date">26/06/2020</td>
                            <td class="table_td_content">Apresentação dos projetos</td>
                        </tr>
                    </tbody>
                        
                    
                </table>
                </Card.Body>
                </Accordion.Collapse>
            </Card>    

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="4">
                Bibliografia
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body>
                <h6>Básica</h6>
          
                <p>Dominando o Visual Studio .NET com C#. Fábio Câmara, 2a Edição. Florianópolis: Visual Books, 2005.</p>
                    
                <h6>Complementar</h6>
                
                <p>Beginning C# Programando. Karli Watson. 1ª Edição, São Paulo. Makron Books. 2002.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="5">
                Critérios de avaliação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body>
                    <h6>Instrumentos de Avaliação:</h6>
                
                        <ul>
                            <li>Avaliação (AV);</li>
                            <li>Projeto (PJ);</li>
                            <li>Nota de Participação (NP).</li>
                        </ul>
                    
                    <h6>Critérios de Avaliação:</h6>
                    <br/>

                    <p class="content_centralized">
                    MD = (AV * 0.4) + (PJ * 0.4) + (NP * 0.2)
                    </p>

                    <p>O aluno será considerado <b>aprovado</b> na disciplina se sua média final (MD) for maior ou igual a 6.0 (MD >= 6.0) e tiver <b>frequência mínima</b> de 75% (setenta e cinco por cento)
                        às aulas e demais atividades escolares.</p>
                        
                    <p>O aluno será considerado <b>reprovado</b> na disciplina se não tiver <b>frequência mínima</b> de 75% (setenta e cinco por cento) e/ou média final na disciplina após o IFA for menor que 6.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="6">
                Mecanismos de recuperação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body>
                <p>
                    Para os alunos que obtiverem média final (MD) maior ou igual a 4,0 (MD >= 4.0) e menor que 6,0, será aplicado o IFA 
                    (Instrumento Final de Avaliação), desde que possuam frequência mínima de 75%.
                </p>

                <p>Nesse caso a média final do aluno será a nota obtida no IFA.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="7">
                Abono de faltas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                <Card.Body>
                <p>O Art. 43, da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP</a>, prevê o abono das faltas nos seguintes casos:</p>
            
                <ul>
                    <li>Declaração de corporação militar, comprovando ausência;</li>
                    <li>Comprovante de participação do estudante em reuniões da Comissão Nacional de Avaliação da educação superior (CONAES) em horário coincidente com as atividades acadêmica (Lei nº 10861/04);</li>
                    <li>Declaração do Diretor-Geral do campus, comprovando que o estudante esteve representando o IFSP;</li>
                    <li>Atestado médico para os casos previstos em lei.</li>
                    <li>Certidão de óbito de parentes de 1º (primeiro) grau ou cônjuge;</li>
                    <li>Solicitação judicial.</li>
                </ul>

                <p>Para maiores informações consultar a <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>

                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="8">
                Aproveitamento da disciplina
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                <Card.Body>
                <p>Conforme o Art. 101 da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP</a>: Os estudantes terão direito a aproveitamento de estudos dos 
                    componentes curriculares já cursados, com aprovação no IFSP ou em instituição congênere, desde que realizados com êxito e dentro do mesmo nível de ensino.</p>

                <p>Para maiores informações consultar o CAPÍTULO VI da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>   
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            </Accordion>   

             <br/>
             <br/>
             <br/>
    </div>
  );
}

export default A6LP2;
