import React, {useEffect}  from 'react';

import { fadeIn  } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import Grow from '@material-ui/core/Grow';

import ReactGA from 'react-ga';

import '../App.css';

const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  }
}

function Project() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="project">
      <h1 className="page-title">Projetos</h1>
          <div className="row">
            <div className="col-md-4 col-sm-12 project-card">            
            <Grow in={true} timeout={250}>
              <div className="card" style={styles.fadeIn}>
                <img src="/projects/gerar_cv_project.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">Gerar Currículo</h5>
                  <p className="card-text">Website para geração de currículos formatados.</p>
                </div>
              </div>
              </Grow>
            </div>

            <div className="col-md-4 col-sm-12 project-card">
            <Grow in={true} timeout={700}>
              <div className="card" style={styles.fadeIn}>
                <img src="/projects/ifreport.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">IFReport</h5>
                  <p className="card-text">Gestão de ocorrências nos labs de informática do IFSP.</p>
                </div>
              </div>
              </Grow>
            </div>

            <div className="col-md-4 col-sm-12 project-card">
            <Grow in={true} timeout={1200}>
              <div className="card" style={styles.fadeIn}>
                <img src="/projects/alavadeira.jpg" className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">A lavadeira</h5>
                  <p className="card-text">Solução Multiplataforma para coleta de roupas.</p>
                </div>
              </div>
              </Grow>
            </div>
          </div>
    </div>
  );
}

export default Project;
