import React from 'react';

import {
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

  import { Accordion, Card } from 'react-bootstrap'; 

import '../../../../App.css';

function SIN() {

  let { path, url } = useRouteMatch();

  return (
    <div className="course">
      <h3 className="page-title" id="topo" >SIN - Segurança da Informação</h3>

      <h6>Instituto Federal de Educação, Ciência e Tecnologia de São Paulo, Campus São Paulo.</h6>
          
          <h6>2020 - Tecnico em Informática Integrado ao Ensino Médio</h6> 
          
          <h6>Professor: Thiago Inocêncio</h6> <br/>
          
          <h5 class="page-sub-title">Avisos importantes (fique atento sempre!)</h5>
          <p>

            [30/03] Últimos avisos; <br />   <br /> 

            - A reitoria do IFSP lançou uma <a href="https://www.ifsp.edu.br/images/reitoria/Portarias/2020/portaria1200.pdf" target="_blank">
                portaria</a> suspendendo por tempo indeterminado as aulas presenciais e à distância; <br /> 
            - Infelizmente não consiguiremos realizar/adiantar as aulas remotamente por conta de diversos fatores que foram levantados junto aos alunos; <br />
            - Neste momento estou preparando conteúdo audiovisual complementar com base no conteúdo da disciplina;<br /><br />

            - Quaisquer dúvidas ou sugestões envie um e-mail para: <a href="mailto:inocencio.thiago@ifsp.edu.br">inocencio.thiago@ifsp.edu.br</a>.



            <br/><br/>

            [11/02] - <a href="https://spo.ifsp.edu.br/destaques/2144-atividades-suspensas-no-campus-no-periodo-matutino-amanh%C3%A3,-11-02-2020,-para-limpeza-e-organiza%C3%A7%C3%A3o-dos-espa%C3%A7os" target="_blank">
              A aula de hoje está cancelada devido aos alagamentos na cidade.</a> <br/>
            [08/02] - Página da disciplina no ar.

          </p>
          
          <br/>
          <h5 class="page-sub-title">Conteúdos desta página</h5>

          <Accordion>
            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="0">
                Dias, horários e local das aulas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <p>Terças-feiras, das 18h50 às 20h20, sala (a definir)</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>  

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="1">
                Dias, horários e local de atendimento
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                <p>Terças-feiras, das 16h às 18h e das 20h20 às 21h20, sala dos professores.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="2">
                Ementa, objetivos e conteúdo programático da disciplina
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                <Card.Body>
                <h6>Ementa</h6>
                <p>
                    Fundamentos de Segurança da Informação, Segurança Física e Segurança Lógica, Dispositivos Biométricos, Controle de
                    Acesso, Segurança de Redes, Ferramentas de Ataque e Defesa, Vulnerabilidades, Forense Computacional, Normas e
                    Padrões de Segurança da Informação, Auditoria de Sistemas de Informação, Uso de Criptografia, Certificação e
                    Assinatura Digital.           
                </p>

                <h6>Objetivos</h6>

                <p>
                    Capacitar o aluno fornecendo subsídios e instrumentos que ajudem a garantir a Segurança da Informação de uma
                    organização. Tal objetivo será alcançado através da compreensão dos conceitos básicos de ativos, ameaças, riscos e
                    vulnerabilidades, complementados por ferramentas que atuem nos ambientes lógicos, físicos, legais e culturais de uma
                    organização.
                </p>
                
                <h6>Conteúdo programático</h6>
                
                <p>Fundamentos de Segurança da Informação: privacidade, confidencialidade, autenticidade, integridade,
                    disponibilidade; vulnerabilidade, risco, ameaça, ataque, engenharia social;
                    Segurança Física e Segurança Lógica;
                    Tipos de Ameaça. Mecanismos de Defesa contra Ataques;
                    Segurança de Redes. Uso de criptografia;
                    Controle de Acesso;
                    Forense Computacional;
                    Normas e Padrões de Segurança da Informação;
                    Certificação e Assinatura Digital;
                    Conformidade da Segurança da informação com aspectos legais;
                    Continuidade do Negócio;
                    Ferramentas para verificação e proteção de redes.            
                </p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="3">
                Cronograma
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                <Card.Body>
                <p>É obrigatória a frequência às aulas e demais atividades escolares. A frequência mínima para efeitos de aprovação
               é de 75% (setenta e cinco por cento) sobre o total de aulas e demais atividades escolares.</p>

               <p><b>Ver também</b> (<a href="#abono-faltas">ABONO DE FALTAS</a>).</p>

                <table class="table_crono">
                    <thead>
                        <tr>
                            <th class="table_th_weak">Semana</th> 
                            <th class="table_th_date">Data</th> 
                            <th class="table_th_content">Conteúdo</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="table_th_weak">1</td>
                            <td class="table_th_date">11/02/2020</td>
                            <td class="table_td_content"><span style={{textDecoration:"line-through"}}>Introdução à disciplina</span> (Aula cancelada). </td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">2</td>
                            <td class="table_th_date">18/02/2020</td>
                            <td class="table_td_content">Introdução à disciplina.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">3</td>
                            <td class="table_th_date">25/02/2020</td>
                            <td class="table_td_content">Feriado Carnaval <b>(atividade extra em 29/02/2020 - Segurança de computadores).</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">5</td>
                            <td class="table_th_date">10/03/2020</td>
                            <td class="table_td_content">Fundamentos de segurança da informação I: Privacidade e Confidencialidade.</td>
                        </tr>
                        
                        <tr>
                            <td class="table_th_weak">5</td>
                            <td class="table_th_date">10/03/2020</td>
                            <td class="table_td_content">Fundamentos de segurança da informação II: Autenticidade e integridade.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">6</td>
                            <td class="table_th_date">17/03/2020</td>
                            <td class="table_td_content">Fundamentos de segurança da informação III: Disponibilidade e Vulnerabilidade.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">7</td>
                            <td class="table_th_date">24/03/2020</td>
                            <td class="table_td_content">Fundamentos de segurança da informação IV: Risco e ameaça.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">8</td>
                            <td class="table_th_date">31/03/2020</td>
                            <td class="table_td_content">Fundamentos de segurança da informação V: Ataques (Ética de raqueamento).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">9</td>
                            <td class="table_th_date">07/04/2020</td>
                            <td class="table_td_content"><b>Avaliação - 1º Bimestre.</b></td>
                        </tr>
                        
                        <tr>
                            <td class="table_th_weak">10</td>
                            <td class="table_th_date">14/04/2020</td>
                            <td class="table_td_content">Correção da prova e avaliação de recuperação (Última aula do 1º Bimestre).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">11</td>
                            <td class="table_th_date">21/04/2020</td>
                            <td class="table_td_content">Feriado Tiradentes <b>(Atividade extra de pesquisa e/ou lista de exercícios).</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">12</td>
                            <td class="table_th_date">28/04/2020</td>
                            <td class="table_td_content">Mecanismos de defesa contra ataques I: Ferramentas de controle contra malwares (Antivírus, antimalware).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">13</td>
                            <td class="table_th_date">05/05/2020</td>
                            <td class="table_td_content">Mecanismos de defesa contra ataques II: Camada de proteção da rede (Firewall).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">14</td>
                            <td class="table_th_date">12/05/2020</td>
                            <td class="table_td_content">Conselho pedagógico <b>(Atividade extra de pesquisa e/ou lista de exercícios).</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">15</td>
                            <td class="table_th_date">19/05/2020</td>
                            <td class="table_td_content">Mecanismos de defesa contra ataques III: Ocultação de informações (esteganografia).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">16</td>
                            <td class="table_th_date">26/05/2020</td>
                            <td class="table_td_content">Criptografia I: Criptografia de chave simétrica.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">17</td>
                            <td class="table_th_date">02/06/2020</td>
                            <td class="table_td_content">Criptografia II: Criptografia de chave assimétrica.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">18</td>
                            <td class="table_th_date">09/06/2020</td>
                            <td class="table_td_content">Ferramentas para verificação e proteção de redes I: Escaneamento de vulnerabilidades e Detecção de intrusão.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">19</td>
                            <td class="table_th_date">16/06/2020</td>
                            <td class="table_td_content">Ferramentas para verificação e proteção de redes II: segurança física e segurança lógica.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">20</td>
                            <td class="table_th_date">23/06/2020</td>
                            <td class="table_td_content"><b>Avaliação - 2º Bimestre.</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">21</td>
                            <td class="table_th_date">30/06/2020</td>
                            <td class="table_td_content">Correção da prova e avaliação de recuperação (Última aula do 2° bimestre).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">22</td>
                            <td class="table_th_date">04/08/2020</td>
                            <td class="table_td_content">Ataques e testes de invação I: Tipos de ataques.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">23</td>
                            <td class="table_th_date">11/08/2020</td>
                            <td class="table_td_content">Ataques e testes de invação II: Ataques de engenharia social.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">24</td>
                            <td class="table_th_date">18/08/2020</td>
                            <td class="table_td_content">Ataques e testes de invação III: Ciclo de vida dos testes de invasão.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">25</td>
                            <td class="table_th_date">25/08/2020</td>
                            <td class="table_td_content">Ataques e testes de invação IV: Reconhecimento.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">26</td>
                            <td class="table_th_date">01/09/2020</td>
                            <td class="table_td_content">Ataques e testes de invação V: Escaneamento (scanning).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">27</td>
                            <td class="table_th_date">08/09/2020</td>
                            <td class="table_td_content">Ataques e testes de invação IV: Exploração de falhas (exploitation).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">28</td>
                            <td class="table_th_date">15/09/2020</td>
                            <td class="table_td_content">Ataques e testes de invação VI: Preservação de acesso (rootkits, backdoors, botnets).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">29</td>
                            <td class="table_th_date">22/09/2020</td>
                            <td class="table_td_content"><b>Avaliação - 3º Bimestre.</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">30</td>
                            <td class="table_th_date">29/09/2020</td>
                            <td class="table_td_content">Correção da prova e avaliação de recuperação - (Última aula do 3º Bimestre).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">31</td>
                            <td class="table_th_date">06/10/2020</td>
                            <td class="table_td_content">Normas e padrões de segurança da informação I: Certificação e Assinatura Digital.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">32</td>
                            <td class="table_th_date">13/10/2020</td>
                            <td class="table_td_content">Antecipação do dia dos professores <b>(Recesso - Atividade extra de pesquisa e/ou lista de exercícios).</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">33</td>
                            <td class="table_th_date">20/10/2020</td>
                            <td class="table_td_content">Normas e padrões de segurança da informação II: Conformidade da segurança da informação com aspectos legais 
                            (leis, decretos, portarias, instruções normativas, lei de acesso a informação).</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">34</td>
                            <td class="table_th_date">27/10/2020</td>
                            <td class="table_td_content">Normas e padrões de segurança da informação III: Continuidade do negócio.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">35</td>
                            <td class="table_th_date">03/11/2020</td>
                            <td class="table_td_content">Computação forense I: Crimes realizados com dispositivos computacionais.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">36</td>
                            <td class="table_th_date">10/11/2020</td>
                            <td class="table_td_content">Computação forense II: Locais de crime envolvendo equipamentos computacionais.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">37</td>
                            <td class="table_th_date">17/11/2020</td>
                            <td class="table_td_content">Computação forense III: Exames forenses, Análise de conteúdo eletrônico e elaboração de laudos forenses.</td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">38</td>
                            <td class="table_th_date">24/11/2020</td>
                            <td class="table_td_content"><b>Avaliação - 4º Bimestre.</b></td>
                        </tr>

                        <tr>
                            <td class="table_th_weak">39</td>
                            <td class="table_th_date">01/12/2020</td>
                            <td class="table_td_content">Correção da prova e avaliação de recuperação - (Última aula do 4º Bimestre).</td>
                        </tr>


                    </tbody>
                        
                    
                </table>

                </Card.Body>
                </Accordion.Collapse>
            </Card>


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="4">
                Bibliografia
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body>
                <h6>Básica</h6>
          
                <p>BASTA, Alfred; BASTA, Nadine; Brown Mary; Segurança de computadores e teste de invasão, São Paulo; Editora
                    Cengage Learning; 2015.</p>
                    
                <p>WADLOW, Thomas A.; Segurança de redes: projeto e gerenciamento de redes seguras, Rio de Janeiro, Editora
                    Campus, 2000.</p>

                <p>MACHADO, Felipe Nery Rodrigues; Segurança da informação - princípios e controle de ameaças; São Paulo, Editora
                    Érica, 2014. </p>

                <p>WEIDMAN, Georgia; Testes de invasão, São Paulo, Editora: Erica, 2014.</p>
                    
                <h6>Complementar</h6>
                
                <p>RUFINO, Nelson Murilo de O. , Segurança Nacional – Técnicas e Ferramentas de Ataque e Defesa de Redes de
                    Computadores, São Paulo, Editora Novatec, 2002.
                </p>
                    
                <p>NORTHCUT, S. & ZELTSER, L., Desvendando Segurança em Redes, Rio de Janeiro, Editora Campus, 2003.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="5">
                Instrumentos e Critérios de Avaliação da Aprendizagem 
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body>
                <h6>Instrumentos de Avaliação bimestral:</h6>
        
                <ul>
                    <li>Avaliação (`AV`);</li>
                    <li>Listas de exercícios (`LE`);</li>
                    <li>Pesquisas (`PE`);</li>
                </ul>
                
            
                <h6>Critérios de Avaliação:</h6>

                <p class="content_centralized">
                    `MB = (AV * 0.6) + (LE * 0.2) + (PE * 0.2)`
                </p>
                
                <p>
                    *`MB` (Média bimestral)
                </p>
                
                <p>
                    Os critérios de avaliação poderão ser ajustados a cada bimestre (tanto em relação a pesos quanto a componentes avaliativos), de acordo com o tipo
                    de conteúdo. Os critérios serão sempre apresentados na primeira aula de cada bimestre para a turma.
                </p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="6">
                Mecanismos de recuperação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body>                
                    <p>
                        Para os alunos que obtiverem média final (`MD`) maior ou igual a 4,0 (`MD >= 4.0`) e menor que 6,0, será aplicado uma reavaliação, desde que possuam frequência mínima de 75%.
                    </p>

                    <p>Nesse caso a média final do aluno será a nota obtida na reavaliação.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="7">
                Abono de faltas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                <Card.Body>                
                    <p>O Art. 43, da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP</a>, prevê o abono das faltas nos seguintes casos:</p>
                
                    <ul>
                        <li>Declaração de corporação militar, comprovando ausência;</li>
                        <li>Comprovante de participação do estudante em reuniões da Comissão Nacional de Avaliação da educação superior (CONAES) em horário coincidente com as atividades acadêmica (Lei nº 10861/04);</li>
                        <li>Declaração do Diretor-Geral do campus, comprovando que o estudante esteve representando o IFSP;</li>
                        <li>Atestado médico para os casos previstos em lei.</li>
                        <li>Certidão de óbito de parentes de 1º (primeiro) grau ou cônjuge;</li>
                        <li>Solicitação judicial.</li>
                    </ul>

                    <p>Para maiores informações consultar a <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>


           </Accordion>   

        <br />
        <br />
        <br />
    </div>
  );
}

export default SIN;
