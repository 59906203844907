import React, { useState, useEffect}  from 'react';

import {
    BrowserRouter as Router,
    Link,
    useHistory
  } from "react-router-dom";

  
import { Image } from 'react-bootstrap';

import '../App.css';

function SideMenu(props) {

    let history = useHistory();
    let pathName = history.location.pathname;

  return (
    <div id="side_menu" className="sticky">
        <div className={props.darkTheme ? "menu_header menu_header_dark" : "menu_header"}>
              <Image src="/profile.jpg" className="profile_photo"  width="90" height="90" alt="my profile photo"/>
         
        </div>
        <ul className={props.darkTheme ? "font-dark menu_list" : "menu_list"}>
            <li className={"list-item " + (pathName === "/" ? "list-item-selected" : "")}><Link to="/">👋 Sobre</Link></li>
            <li className={"list-item " + (pathName.includes("teaching") ? "list-item-selected" : "")}><Link to="/teaching">👨‍🏫 Aulas</Link></li>
            <li className={"list-item " + (pathName.includes("blog") ? "list-item-selected" : "")}><Link to="/blog">✍️ Blog</Link></li>
            <li className={"list-item " + (pathName.includes("publication") ? "list-item-selected" : "")}><Link to="/publication">📝 Publicações</Link></li>
            <li className={"list-item " + (pathName.includes("projects")  ? "list-item-selected" : "")}><Link to="/projects">💻 Projetos</Link></li>
            <li className={"list-item " + (pathName.includes("photos")  ? "list-item-selected" : "")}><Link to="/photos">📷 Fotos</Link></li>
            <li className={"list-item " + (pathName.includes("tools")  ? "list-item-selected" : "")}><Link to="/tools">🛠️ Ferramentas</Link></li>
        </ul>
    </div>
  );
}

export default SideMenu;