import React, { useState, useEffect}  from 'react';
import './App.css';

import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';

import Typewriter from 'typewriter-effect';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation 
} from "react-router-dom";

// Declaring components
import Project from './components/Project.js';
import About from './components/About.js';
import Tools from './components/Tools/Tools.js';
import SideMenu from './components/SideMenu.js';
import Blog from './components/Blog/Blog.js';
import Publication from './components/Publication/Publication.js';
import Teaching from './components/Teaching/Teaching.js';
import Photos from './components/Photos/Photos.js';
import Analysis from './components/Analysis/Analysis.js';
import ReactGA from 'react-ga';

import { Icon } from 'react-icons-kit'
import {moon} from 'react-icons-kit/iconic/moon'
import {ic_wb_sunny} from 'react-icons-kit/md/ic_wb_sunny'

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function App() {


  const [navbarExpand, setNavbarExpand] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);

  const onSelectOption = () => {
    setNavbarExpand(false);
  }

  const onClick = () => {
    setNavbarExpand(!navbarExpand);
  }

  const location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);  

  React.useEffect(() => {
    ReactGA.initialize('UA-192814792-1', {
      debug: false
    });
  }, []);

  const handleChangeTheme = () => {
     setDarkTheme(!darkTheme);
  }

  return (
      <div className={ darkTheme ? "App App-dark" : "App"}>
          <Navbar expanded={navbarExpand} expand="lg" fixed="top" className={ darkTheme ? "navbar navbar-dark" : "navbar"}>
            
          <Container>
              <Link to={`/`}>
                <Navbar.Brand className={darkTheme ? "website-name-dark website-name" : "website-name"}>
                <div style={{"width": 200, "text-align": "left"}}>
                <Typewriter
                        options={{
                          delay: 50,
                        }}
                        onInit={(typewriter) => {


                          typewriter
                          .typeString('thiagoinocencio.com')
                            .pauseFor(1000)
                            .deleteAll(1)
                            .typeString('Thiago Inocêncio')
                            .pauseFor(100)
                            .callFunction(function(state) {
                              state.elements.cursor.style.display = 'none';
                            })
                            .start();

                        }}
                      />
                    </div>
                </Navbar.Brand>
              </Link>

              <div className="justify-content-end theme_icon" onClick={handleChangeTheme} >
                <Navbar.Text>
                    <Icon size={20} icon={darkTheme ? ic_wb_sunny : moon}/>
                </Navbar.Text>
            </div>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={onClick} />
              <Navbar.Collapse id="navbarsExample07">
                <Nav className="mr-auto" id="menu" style={{textAlign: "left", paddingLeft: 2}}>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/`}>Sobre</Link></Nav.Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/teaching`}>Aulas</Link></Nav.Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/blog`}>Blog</Link></Nav.Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/tools`}>Ferramentas</Link></Nav.Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/publication`}>Publicações</Link></Nav.Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/projects`}>Projetos</Link></Nav.Link>
                  <Nav.Link  onClick={onSelectOption}><Link to={`/photos`}>Fotos</Link></Nav.Link>
                </Nav>
              </Navbar.Collapse>
              </Container>
          </Navbar>

          <div className="flex-shrink-0">
            <Container className="menu_container">
               <Row>
                 <Col md={2}>
                   <SideMenu darkTheme={darkTheme} />
                 </Col>

                 <Col md={12} lg={10} className="content">        

                    <Switch>

                    <Route exact path="/index.html">
                        <About />
                      </Route>

                      <Route exact path="/">
                        <About />
                      </Route>

                      <Route exact path="/tools">
                        <Tools />
                      </Route>

                      <Route path="/teaching/">
                        <Teaching />
                      </Route>

                      <Route path="/blog">
                        <Blog />
                      </Route>

                      <Route exact path="/publication">
                        <Publication />
                      </Route>

                      <Route exact path="/projects">
                        <Project />
                      </Route>

                      <Route exact path="/photos">
                        <Photos />
                      </Route>

                      <Route exact path="/3b671c883959a8ef434b85a104c293d4">
                        <Analysis />
                      </Route>


                    </Switch>

                 </Col>


               </Row>
            </Container>
          </div>

      </div>
  );
}

export default App;
