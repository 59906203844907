import React, {useEffect} from 'react';

import '../../App.css';

function Analysis() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
    <h1 className="page-title">R Analysis</h1>
          <div className="content_body">
            <iframe src="./analysis.html" style={{width: "100%", height: 700}}/>           
        </div>
    </div>
  );
}

export default Analysis;