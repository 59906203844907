import React, { useState, useEffect } from 'react';


import {
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import Gallery from 'react-grid-gallery';

  import ReactGA from 'react-ga';

  import '../../App.css';

 /* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
   for (var i = array.length - 1; i > 0; i--) {
       var j = Math.floor(Math.random() * (i + 1));
       var temp = array[i];
       array[i] = array[j];
       array[j] = temp;
   }
} 


function Photos() {

  let { path, url } = useRouteMatch();

  const[photos, setPhotos] = useState([]);

  useEffect(() => {

        window.scrollTo(0, 0);

        setPhotos([
         {
            src: "https://thiagoinocencio.com/photos_bucket/18_corrida_e_caminhada_graac.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_18_corrida_e_caminhada_graac.jpg",
            thumbnailWidth: 110,
            thumbnailHeight: 174,
            caption: "18ª Corrida GRAAC (2018)"
         },
        {
            src: "https://thiagoinocencio.com/photos_bucket/IMG-20160921-WA0005.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG-20160921-WA0005.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Apresentações dos projetos de pesquisa. SEDCITED 2016 do IFSP. São Paulo"
         },
   
        {
            src: "https://thiagoinocencio.com/photos_bucket/HRXE7411.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_HRXE7411.jpg",
            thumbnailWidth: 220,
            thumbnailHeight: 174,
            caption: "Mergulho em Maragogi. Novembro de 2018 - Maragogi, Alagoas."
         },
   
         {
            src: "https://thiagoinocencio.com/photos_bucket/sbes.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_sbes.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Apresentação no SBES. Setembro de 2019 - Salvador, Bahia."
         },   
         {
            src: "https://thiagoinocencio.com/photos_bucket/ifsp_vista_bloco_c.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_ifsp_vista_bloco_c.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Área externa vista da entrada do bloco C do IFSP Campus São Paulo - 2016"
         },   
         {
            src: "https://thiagoinocencio.com/photos_bucket/belohorizonte.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_belohorizonte.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Praça da liberdade em Belo Horizonte (abril de 2019)"
         },   
         {
            src: "https://thiagoinocencio.com/photos_bucket/campos.png",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_campos.jpg",
            thumbnailWidth: 140,
            thumbnailHeight: 174,
            caption: "Campos do Jordão, São Paulo."
         },
   
         {
            src: "https://thiagoinocencio.com/photos_bucket/589cb322175020004.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_589cb322175020004.jpg",
            thumbnailWidth: 240,
            thumbnailHeight: 174,
            caption: "Universidade Federal do ABC - Santo André, São Paulo."
         },
   
         {
            src: "https://thiagoinocencio.com/photos_bucket/ifsp_salao_principal.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_ifsp_salao_principal.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Salão principal do Instituto Federal de São Paulo Campus São Paulo - 2016."
         },
   
         {
            src: "https://thiagoinocencio.com/photos_bucket/livros.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_livros.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Quandos os livros cambiam na cama era bom demais... hoje em dia kkkk (novembro de 2015)"
         },
   
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_6215.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_6215.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Kit de estudos para automação e internet das coisas."
         },
   
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG-20160803-WA0003.jpg",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG-20160803-WA0003.jpg",
            thumbnailWidth: 250,
            thumbnailHeight: 174,
            caption: "Olimpíadas 2016. Jogos de futebol feminino"
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/nuvemII.png",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_nuvemII.jpg",
            thumbnailWidth: 140,
            thumbnailHeight: 174,
            caption: "Apresentação no III Workshop Nuvem. 2019, Santo André, São Paulo."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_0751.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_0751.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "São Paulo, São Paulo."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_1316.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_1316.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "Novo Oriente, Minas Gerais."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_1342.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_1342.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "Novo Oriente, Minas Gerais."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_2137.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_2137.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "Trancoso-Porto Seguro, Bahia."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_2140.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_2140.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "Trancoso-Porto Seguro, Bahia."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_2154.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_2154.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "Trancoso-Porto Seguro, Bahia."
         },           
         {
            src: "https://thiagoinocencio.com/photos_bucket/IMG_2156.JPG",
            thumbnail: "https://thiagoinocencio.com/photos_bucket/t_IMG_2156.jpg",
            thumbnailWidth: 155,
            thumbnailHeight: 116,
            caption: "Trancoso-Porto Seguro, Bahia."
         }
      ].sort(function() {
         return .5 - Math.random();
       }));
   }, []);

  return (
    <div>
    <h1 className="page-title">Fotos</h1>
          <div className="content_body">
          <div className="row" style={{marginBottom: 20}}>
            <div className="col-md-12 col-lg-12">
                 <Gallery enableImageSelection={false} images={photos} backdropClosesModal={true}/>
                 </div>
            </div>
        </div>
    </div>
  );
}

export default Photos;
