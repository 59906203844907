import React, {useEffect} from 'react';
import Grow from '@material-ui/core/Grow';

import className from 'classnames';

import { Image } from 'react-bootstrap';

import ReactGA from 'react-ga';

import {
  useRouteMatch
} from "react-router-dom";

import '../App.css';

function About() {

  let { url } = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

    
    <div className={className("row","justify-content-md-center")}>
        <div className="col-md-4" style={{textAlign: "center"}}>
          <Image src="/profile.jpg" className="profile_photo_about"  width="90" height="90" alt="my profile photo"/>
        </div>
    </div>

    <h1 className="page-title">Sobre</h1>
          <div className="content_body">
          <div className="row" style={{marginBottom: 10}}>
            <div className="col-md-12 col-lg-12">
             <Grow in={true} timeout={250}>
              <p id="about_text">
              Me. Thiago de Jesus Inocêncio é professor substituto no Instituto Federal de Educação, Ciência e Tecnologia de São Paulo. 
              Mestre em Ciência da Computação pela Universidade Federal do ABC (UFABC). 
              Graduado em Tecnologia em Análise e Desenvolvimento de Sistemas pelo Instituto Federal de Educação, 
              Ciência e Tecnologia de São Paulo.</p>
               
            </Grow>
            </div>
          </div>

          <div className="social_links">

            <Grow in={true} timeout={500}>
            <a href="https://github.com/ThiagoInocencio" target="_blank">
              <img src="icons/github.png" width="40" height="40" alt="Github" title="Github" style={{marginLeft: -8}}/>
            </a>
            </Grow>

            <Grow in={true} timeout={1000}>
            <a href="https://www.linkedin.com/in/thiago-inocencio/" target="_blank" >
              <img src="icons/linkedin.png" width="30" height="30" alt="Linkedin" title="Linkedin" style={{marginLeft: 14}}/>
            </a>
            </Grow>

            <Grow in={true} timeout={1500}>
            <a href="http://lattes.cnpq.br/3293916846335475" target="_blank">
              <img src="icons/lattes.png" width="30" height="30" alt="Currículo Lattes" style={{marginLeft: 19}}
                title="Currículo Lattes" />
            </a>
            </Grow>

            <Grow in={true} timeout={2000}>
            <a target="_blank" href="https://www.researchgate.net/profile/Thiago_Inocencio" >
              <img src="icons/rg.png" width="30" height="30" alt="Research Gate" title="Research Gate" style={{marginLeft: 20}} />
            </a>
            </Grow>

            <Grow in={true} timeout={3000}>
            <a target="_blank" href="https://scholar.google.com.br/citations?user=bkZQWeUAAAAJ"
              >
              <img src="icons/g-scholar.png" width="30" height="30" alt="Google Scholar" style={{marginLeft: 20}}
                title="Google Scholar" />
            </a>
            </Grow>

          </div>

          {/* <Grow in={true} timeout={250}>
          <h2 className="page-title-h2">Principais interesses de pesquisa</h2>
          </Grow>

           <Grow in={true} timeout={350}>
            <ul>
              <li>Arquitetura e Engenharia de Sistemas complexos;</li>
              <li>Arquitetura baseada em microserviços;</li>
              <li>Internet das Coisas.</li>
            </ul>
            </Grow> */}

            <Grow in={true} timeout={450}> 
            <h2 className="page-title-h2">Contato</h2>
            </Grow> 
            
            
            <Grow in={true} timeout={450}>
            <p><a href="mailto:hi@thiagoinocencio.com" className="contact_link">hi@thiagoinocencio.com</a></p>
            </Grow>

            </div>
    </div>
  );
}

export default About;