import React, {useEffect} from 'react';

import '../../App.css';
import Grow from '@material-ui/core/Grow';
import ReactGA from 'react-ga';

function Publication() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="publications">
      <h1 className="page-title">Publicações</h1>
          <Grow in={true} timeout={250}>
          <h2>2019</h2>
          </Grow>
         <Grow in={true} timeout={250}>
          <p>
            INOCÊNCIO, THIAGO J.; GONZALES, GUSTAVO R. ; CAVALCANTE, EVERTON ; HORITA, FLÁVIO E. A. . <b><a
                href="http://dx.doi.org/10.1145/3350768.3350779" target="_blank">Emergent Behavior
                in System-of-Systems.
              </a></b> In: the XXXIII Brazilian Symposium, 2019, Salvador. Proceedings of the XXXIII
            Brazilian Symposium on Software Engineering - SBES 2019, 2019. p. 140.
          </p>
          </Grow>
          <Grow in={true} timeout={350}>
          <p>J. INOCÊNCIO, THIAGO; R. GONZALES, GUSTAVO ; E. A. HORITA, FLÁVIO. <b><a
                href="https://sol.sbc.org.br/index.php/bware/article/view/7505" target="_blank">PASoS: Processo para
                definição da
                arquitetura de Sistemas-de-Sistemas.</a></b> In: I Brazilian Workshop on Largescale Critical Systems,
            2019,
            Salvador, BA. Anais do I Brazilian Workshop on Large-scale Critical Systems (BWare 2019), 2019. p. 25.
          </p>
          </Grow>
          <Grow in={true} timeout={450}>
          <p>HORITA, F. E. A. ; RHODES, D. ; INOCÊNCIO, T. J. ; GONZALES, G. R. . <b><a
                href="http://dx.doi.org/10.1145/3330204.3330215" target="_blank">Building a conceptual architecture
                and stakeholder map of a system-of-systems for disaster monitoring and early-warning: A case study in
                Brazil.</a></b> In: Simpósio Brasileiro de Sistemas de Informação (SBSI), 2019, Aracaju, Sergipe.</p>
          </Grow>    

          <Grow in={true} timeout={550}>
          <p>FIGUEIREDO, A. L. ; INOCÊNCIO, T. J. ; GONZALES, G. R. ; HORITA, F. E. A. . <b><a
                href="https://sol.sbc.org.br/index.php/wcama/article/view/6433" target="_blank">Projeto de um aplicativo
                mobile para a coleta estruturada de dados e mapeamento de áreas suscetíveis a riscos geológicos. In:
                Workshop de Computação Aplicada à Gestão do Meio Ambiente e Recursos Naturais</a></b>, 2019, Belém - PA.
          </p>
          </Grow>  

          <Grow in={true} timeout={650}>
          <h2>2017</h2>
          </Grow>
          <Grow in={true} timeout={650}>
          <p>INOCÊNCIO, T. J.; MARCONDES, Francisco Supino . <b><a href="files/II_EICPOG.pdf" target="_blank">Engenharia
                de Software aplicada a competições de programação: uma prova de conceito.</a></b> In: II Encontro de
            Iniciação Científica e Pós-Graduação do IFSP campus São Paulo, 2017, São Paulo.</p>
          </Grow>
          <Grow in={true} timeout={750}>
          <h2>2016</h2>
          </Grow>
          <Grow in={true} timeout={750}>
          <p> INOCÊNCIO, T. J.; BALTHAZAR, G. R. ; BEZERRA JUNIOR, M. R. . <b>Construção de Software para Apoio aos
              Laboratórios de Informática do Departamento de Informática e Turismo.</b> In: IV Congresso de Iniciação
            Científica do IFSP campus Itapetininga, 2016, Itapetininga, SP</p>
          </Grow>

          <Grow in={true} timeout={850}>
          <p>INOCÊNCIO, T. J.; BALTHAZAR, G. R. ; BEZERRA JUNIOR, M. R. . <b><a href="files/resumoSIC_2016.pdf"
                target="_blank">IFREPORT: UM SOFTWARE PARA CADASTRO DE OCORRÊNCIAS NOS LABORATÓRIOS DE INFORMÁTICA DO
                IFSP.</a></b> In: VI Seminário de Iniciação Científica do Litoral Norte - Semana Nacional de Ciência e
            Tecnologia, 2016, Caraguatatuba, SP.</p> 
         </Grow>   
      </div>
  );
}

export default Publication;
