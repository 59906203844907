import React from 'react';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

import A6LP2 from './A6LP2/A6LP2.js';
import PFDA1 from './PFDA1/PFDA1.js';
import BDD from './BDD/BDD.js';
import SIN from './SIN/SIN.js';
import LP3_T1 from './LP3_T1/LP3_T1.js';
import LP3_T2 from './LP3_T2/LP3_T2.js';

import '../../../App.css';

function Course() {

  let { path, url } = useRouteMatch();

  let { code } = useParams();

  const courseToRender = () => {
      switch(code) {
          case "A6LP2": return (<A6LP2 />)
          case "PFDA1": return (<PFDA1 />)
          case "BDD": return (<BDD />)
          case "SIN": return (<SIN />)
          case "LP3_T1": return (<LP3_T1 />)
          case "LP3_T2": return (<LP3_T2 />)
      }
  }

  return (
    <div>
      {courseToRender()}   
    </div>
  );
}

export default Course;
