import React from 'react';

import {
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

  import { Accordion, Card } from 'react-bootstrap'; 

import '../../../../App.css';

function SIN() {

  let { path, url } = useRouteMatch();

  return (
    <div className="course">
      <h3 className="page-title" id="topo" >LP3 - Linguagem de Programação 3</h3>

      <h6>Instituto Federal de Educação, Ciência e Tecnologia de São Paulo, Campus São Paulo.</h6>
          
          <h6>2020 - Tecnico em Informática Integrado ao Ensino Médio</h6> 
          
          <h6>Professor: Thiago Inocêncio</h6> <br/>
          
          <h5 class="page-sub-title">Avisos importantes (fique atento sempre!)</h5>
          <p>
              
          [30/03] Últimos avisos; <br />   <br /> 

            - A reitoria do IFSP lançou uma <a href="https://www.ifsp.edu.br/images/reitoria/Portarias/2020/portaria1200.pdf" target="_blank">
                portaria</a> suspendendo por tempo indeterminado as aulas presenciais e à distância; <br /> 
            - Infelizmente não consiguiremos realizar/adiantar as aulas remotamente por conta de diversos fatores que foram levantados junto aos alunos; <br />
            - Neste momento estou preparando conteúdo audiovisual complementar com base no conteúdo da disciplina;<br /><br />

            - Quaisquer dúvidas ou sugestões envie um e-mail para: <a href="mailto:inocencio.thiago@ifsp.edu.br">inocencio.thiago@ifsp.edu.br</a>.



            <br/><br/>  
              
            [11/02] - <a href="https://spo.ifsp.edu.br/destaques/2144-atividades-suspensas-no-campus-no-periodo-matutino-amanh%C3%A3,-11-02-2020,-para-limpeza-e-organiza%C3%A7%C3%A3o-dos-espa%C3%A7os" target="_blank">
            A aula de hoje está cancelada devido aos alagamentos na cidade.</a> <br/>
          [08/02] Página da disciplina no ar.</p>
          
          <br/>
          <h5 class="page-sub-title">Conteúdos desta página</h5>

          <Accordion>
            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="0">
                Dias, horários e local das aulas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                   <p>Terças-feiras, das 13h15 às 15h30, sala (a definir)</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>  

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="1">
                Dias, horários e local de atendimento
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                   <p>Terças-feiras, das 16h às 18h e das 20h20 às 21h20, sala dos professores.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>  

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="3">
                Ementa, objetivos e conteúdo programático da disciplina
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                <Card.Body>
                <h6>Ementa</h6>
                <p>
                  A disciplina apresenta os conceitos teóricos, métodos, técnica e tecnologias para o desenvolvimento de aplicações
                  web mais disseminadas no mercado. Além disso, pretende promover competências para a pesquisas, idealização,
                  prototipação, análise e desenvolvimento de aplicativos para plataformas web, com base em práticas de Design
                  Centrado no Usuário e o emprego eficaz de métodos computacionais e linguagens de programação voltados à
                  concepção e execução de aplicativos funcionais, eficientes e úteis.          
                </p>

                <h6>Objetivos</h6>

                <p>
                  Capacitar o aluno a desenvolver programas utilizando uma linguagem de programação visual C#; a implementar sistemas
                  orientados a objetos utilizando uma linguagem de programação C# a fim de que esse possa exercer atividades
                  profissionais de forma pela na área relacionada.
                </p>
                </Card.Body>
                </Accordion.Collapse>
            </Card> 


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="4">
                Cronograma
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                <Card.Body>
                <p>É obrigatória a frequência às aulas e demais atividades escolares. A frequência mínima para efeitos de aprovação
               é de 75% (setenta e cinco por cento) sobre o total de aulas e demais atividades escolares.</p>
               
               <p>Nesta disciplina é necessária uma frequência de aproximadamente 30 semanas.</p>

               <p class="content_centralized">frequência ` \approx ` 30 semanas.</p>

               <p><b>Ver também</b> (<a href="#abono-faltas">ABONO DE FALTAS</a>).</p>

              <table class="table_crono">
                <thead>
                    <tr>
                        <th class="table_th_weak">Semana</th> 
                        <th class="table_th_date">Data</th> 
                        <th class="table_th_content">Conteúdo</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="table_th_weak">1</td>
                        <td class="table_th_date">11/02/2020</td>
                        <td class="table_td_content"><span style={{textDecoration:"line-through"}}>Introdução à disciplina</span> (Aula cancelada)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">2</td>
                        <td class="table_th_date">18/02/2020</td>
                        <td class="table_td_content">Introdução a disciplina</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">3</td>
                        <td class="table_th_date">25/02/2020</td>
                        <td class="table_td_content">Feriado Carnaval <b>(Atividade extra em 29/02/2020).</b></td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">4</td>
                        <td class="table_th_date">03/03/2020</td>
                        <td class="table_td_content"><a href="/teaching/LP3_T1/files/lectures/1-Fundamentos da plataforma .NET.pdf" target="_blank">Fundamentos da plataforma .NET e Ferramentas de desenvolvimento</a></td>
                    </tr>
                    
                    <tr>
                        <td class="table_th_weak">5</td>
                        <td class="table_th_date">10/03/2020</td>
                        <td class="table_td_content"><a href="/teaching/LP3_T1/files/lectures/1-Fundamentos da plataforma .NET.pdf" target="_blank">Fundamentos da plataforma .NET - Principais recursos</a></td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">6</td>
                        <td class="table_th_date">17/03/2020</td>
                        <td class="table_td_content"><a href="/teaching/LP3_T1/files/lectures/2-Introducao a Linguagem de Programacao Csharp.pdf" target="_blank">Introdução à linguagem de programação C#</a></td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">7</td>
                        <td class="table_th_date">24/03/2020</td>
                        <td class="table_td_content">Conceitos de orientação à objetos na linguagem C#</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">8</td>
                        <td class="table_th_date">31/03/2020</td>
                        <td class="table_td_content">APIs comumente utilizadas: Data e Hora, Manipulação de Strings, Conversões de Tipos</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">9</td>
                        <td class="table_th_date">07/04/2020</td>
                        <td class="table_td_content"><b>Avaliação - 1º Bimestre</b></td>
                    </tr>
                    
                    <tr>
                        <td class="table_th_weak">10</td>
                        <td class="table_th_date">14/04/2020</td>
                        <td class="table_td_content">Correção da prova e avaliação de recuperação - Bimestre 1 (Última aula do 1º bimestre)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">11</td>
                        <td class="table_th_date">21/04/2020</td>
                        <td class="table_td_content">Feriado Tiradentes (Atividade Extra)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">12</td>
                        <td class="table_th_date">28/04/2020</td>
                        <td class="table_td_content">A API de coleções e uma introdução ao LINQ</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">13</td>
                        <td class="table_th_date">05/05/2020</td>
                        <td class="table_td_content">Introdução ao ASP.NET MVC e ao desenvolvimento WEB</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">14</td>
                        <td class="table_th_date">12/05/2020</td>
                        <td class="table_td_content">Conselho pedagógico (Atividade extra)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">15</td>
                        <td class="table_th_date">19/05/2020</td>
                        <td class="table_td_content">ASP.NET MVC e o Model-View-Controller</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">16</td>
                        <td class="table_th_date">26/05/2020</td>
                        <td class="table_td_content">Desenvolvendo a camada de apresentação: a View e Introdução ao Razor</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">17</td>
                        <td class="table_th_date">02/06/2020</td>
                        <td class="table_td_content">Desenvolvendo a camada de apresentação: a View e Introdução ao Razor</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">18</td>
                        <td class="table_th_date">09/06/2020</td>
                        <td class="table_td_content">Introdução ao Entity Framework e o modelo Code-First</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">19</td>
                        <td class="table_th_date">16/06/2020</td>
                        <td class="table_td_content">Ligando a View ao Model: Desenvolvendo um Controlador</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">20</td>
                        <td class="table_th_date">23/06/2020</td>
                        <td class="table_td_content"><b>Avaliação - 2º Bimestre</b></td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">21</td>
                        <td class="table_th_date">30/06/2020</td>
                        <td class="table_td_content">Correção da prova e avaliação de recuperação - 2º Bimestre (Última aula do 2° bimestre)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">22</td>
                        <td class="table_th_date">04/08/2020</td>
                        <td class="table_td_content">Lidando com a navegação: Rotas</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">23</td>
                        <td class="table_th_date">11/08/2020</td>
                        <td class="table_td_content">Juntando os conceitos: Desenvolvendo uma funcionalidade completa</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">24</td>
                        <td class="table_th_date">18/08/2020</td>
                        <td class="table_td_content">Introdução a filtros</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">25</td>
                        <td class="table_th_date">25/08/2020</td>
                        <td class="table_td_content">Filtros, autenticação e autorização</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">26</td>
                        <td class="table_th_date">01/09/2020</td>
                        <td class="table_td_content">Autenticação e autorização</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">27</td>
                        <td class="table_th_date">08/09/2020</td>
                        <td class="table_td_content">SEDCITEC (Participação no evento e produção de relatórios de acompanhamento de palestras e atividades)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">28</td>
                        <td class="table_th_date">15/09/2020</td>
                        <td class="table_td_content">Introdução ao desenvolvimento de serviços WEB.</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">29</td>
                        <td class="table_th_date">22/09/2020</td>
                        <td class="table_td_content"><b>Avaliação - 3º Bimestre.</b></td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">30</td>
                        <td class="table_th_date">29/09/2020</td>
                        <td class="table_td_content">Correção da prova e avaliação de recuperação - 3º Bimestre.</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">31</td>
                        <td class="table_th_date">06/10/2020</td>
                        <td class="table_td_content">Implantação de uma aplicação utilizando serviços de computação em nuvem</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">32</td>
                        <td class="table_th_date">13/10/2020</td>
                        <td class="table_td_content">Antecipação do dia dos professores - recesso (Atividade extra)</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">33</td>
                        <td class="table_th_date">20/10/2020</td>
                        <td class="table_td_content">Implantação de uma aplicação utilizando serviços de computação em nuvem</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">34</td>
                        <td class="table_th_date">27/10/2020</td>
                        <td class="table_td_content">Desenvolvimento de projeto com os conceitos da disciplina</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">35</td>
                        <td class="table_th_date">03/11/2020</td>
                        <td class="table_td_content">Desenvolvimento de projeto com os conceitos da disciplina</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">36</td>
                        <td class="table_th_date">10/11/2020</td>
                        <td class="table_td_content">Desenvolvimento de projeto com os conceitos da disciplina</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">37</td>
                        <td class="table_th_date">17/11/2020</td>
                        <td class="table_td_content">Apresentação dos projetos desenvolvidos</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">38</td>
                        <td class="table_th_date">24/11/2020</td>
                        <td class="table_td_content">Apresentação dos projetos desenvolvidos</td>
                    </tr>

                    <tr>
                        <td class="table_th_weak">39</td>
                        <td class="table_th_date">01/12/2020</td>
                        <td class="table_td_content">Apresentação dos projetos desenvolvidos (Última aula do 4º bimestre)</td>
                    </tr>


                </tbody>
                    
                
            </table>
                </Card.Body>
                </Accordion.Collapse>
            </Card> 

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="5">
                Bibliografia
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                <Card.Body>
                <h6>Básica</h6>
          
                <p>DEITEL, H.M.; DEITEL, P.J.; Java Como Programar. 6a edição. Editora Pearson, 2005.</p>
                  
                <p>FREEMAN, Elisabeth; Freeman Eric; Use a Cabeça! (Head First) HTML com CSS & XHTML. 2a edição.
                  Editora Alta Books, 2008.</p>

                <p>TROELSEN Andrew; Profissional C# e a Plataforma .NET 3.5 - Curso Completo. 1a edição. Editora Alta
                  Books, 2009. </p>
                  
                <h6>Complementar</h6>
                
                <p>GONÇALVES, Edson; Desenvolvendo Aplicações Web com JSP Servlets, JavaServer Faces, Hibernate,
                  EJB 3 Persistence e Ajax. 1a edição. Editora Ciência Moderna Ltda, 2007.
              </p>
                  
                <p>LIBERTY, Jessé; Programando C# 3.0. 2a edição. Editora Alta Books, 2009.</p>

                <p>MORONI, Herbert; Treinamento profissional em C#.Net. 1a edição. Editora Digerati Books, 2006.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>


            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="6">
                Instrumentos e Critérios de Avaliação da Aprendizagem 
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                <Card.Body>
                <h6>Instrumentos de Avaliação bimestral:</h6>
        
                <ul>
                    <li>Avaliação (`AV`);</li>
                    <li>Exercícios (`EX`);</li>
                </ul>
                
              
                <h6>Critérios de Avaliação:</h6>

                <p class="content_centralized">
                    `MB = (AV * 0.7) + (EX * 0.3)`
                </p>
                
                <p>
                    *`MB` (Média bimestral)
                </p>
                
                <p>
                    Os critérios de avaliação poderão ser ajustados a cada bimestre (tanto em relação a pesos quanto a componentes avaliativos), de acordo com o tipo
                    de conteúdo. Os critérios serão sempre apresentados na primeira aula de cada bimestre para a turma.
                </p>
    
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="7">
                Mecanismos de recuperação
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                <Card.Body>
                <p>
                  Para os alunos que obtiverem média final (`MD`) maior ou igual a 4,0 (`MD >= 4.0`) e menor que 6,0, será aplicado uma reavaliação, desde que possuam frequência mínima de 75%.
              </p>

              <p>Nesse caso a média final do aluno será a nota obtida na reavaliação.</p>

    
                </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Accordion.Toggle style={{cursor: "pointer"}} as={Card.Header} eventKey="8">
                Abono de faltas
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                <Card.Body>
                <p>O Art. 43, da <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP</a>, prevê o abono das faltas nos seguintes casos:</p>
            
                <ul>
                    <li>Declaração de corporação militar, comprovando ausência;</li>
                    <li>Comprovante de participação do estudante em reuniões da Comissão Nacional de Avaliação da educação superior (CONAES) em horário coincidente com as atividades acadêmica (Lei nº 10861/04);</li>
                    <li>Declaração do Diretor-Geral do campus, comprovando que o estudante esteve representando o IFSP;</li>
                    <li>Atestado médico para os casos previstos em lei.</li>
                    <li>Certidão de óbito de parentes de 1º (primeiro) grau ou cônjuge;</li>
                    <li>Solicitação judicial.</li>
                </ul>

                <p>Para maiores informações consultar a <a href="http://www2.ifsp.edu.br/index.php/documentos-institucionais/organizacaodidatica.html" target="_blank">Organização Didática do IFSP.</a></p>

    
                </Card.Body>
                </Accordion.Collapse>
            </Card>



           </Accordion>   

        <br />
        <br />
        <br />
    </div>
  );
}

export default SIN;
