import React, {useEffect, useState, useRef } from 'react';
import Grow from '@material-ui/core/Grow';

import className from 'classnames';

import { Image, Form, Row, Col, Button, Tooltip, OverlayTrigger, Overlay } from 'react-bootstrap';
import {getLoremIpsum} from "./Utils/lorem_ipsum.js";

import { AiOutlineCopy } from 'react-icons/ai';

import ReactGA from 'react-ga';


import {
  useRouteMatch
} from "react-router-dom";

import '../../App.css';
import style from './tools.module.css';


function RandomText(params) {

  let { url } = useRouteMatch();
  const inputNParagraphs = useRef(null);
  const inputText = useRef(null);
  const target = useRef(null);

  useEffect(() => {
    inputNParagraphs.current.focus();
    window.scrollTo(0, 0);
  }, []);

  const [nParagraphs, setNParagraphs] = useState(null);
  const [textAreaText, setTextAreaText] = useState(null);
  const [showToolTip, setShowToolTip] = useState(false);
  const [textCopy, setTextCopy] = useState("Copiar para área de transferência");

  const generateText = () => {
    if(nParagraphs !== null && nParagraphs > 0) {
      setTextAreaText(getLoremIpsum(nParagraphs).join("\n\n"));
    }
  }

  const handleChange = (e) => {    

    if(e.target.value > -1 && e.target.value < 1000)
        setNParagraphs(e.target.value);

  }

  const handleCopyToClipBoard = () => {
    if(textAreaText !== "") {
       navigator.clipboard.writeText(textAreaText);
       setTextCopy("Copiado!");  
    }

    setShowToolTip(true);

    setTimeout(() => { setShowToolTip(false);    }, 1000);

  }

  const handleReturnToolsMainPage = () => {
    params.handleReturnTools();
  }


  return (
    <div>
      <h2 className={style.title_tool}><spam class="link_tool" onClick={handleReturnToolsMainPage}>Ferramentas</spam> > <b>Random text generator</b></h2>

      <Form className={style.tool_options} spellcheck="false">
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className={style.label_tool}>Número de parágrafos:</Form.Label>
              <Form.Control onChange={handleChange} min={0} max={999} ref={inputNParagraphs} type="number" placeholder="Número" className="input_tool" />
              <Button variant="success" className={style.button_tool} onClick={() => generateText()}>Gerar</Button>{' '}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <div  className={style.label_tool}>
              Resultado:
              <spam className={style.copy_tool} ref={target} onClick={handleCopyToClipBoard}><AiOutlineCopy /> copiar</spam>  
            </div>
            <Form.Control  as="textarea" rows={15} value={textAreaText} className="textarea_tool" ref={inputText} />
          </Form.Group>
          <Overlay target={target.current} show={showToolTip} placement="top">
              <Tooltip id="overlay-example">
                Copiado!
              </Tooltip>
          </Overlay>
          </Col>
        </Row>

      </Form>

    </div>
  );
}

export default RandomText;